import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  Avatar,
  List,
  Box,
  Button,
  ListItem,
  Dialog,
  DialogContent,
  ListItemIcon,
  ListItemText,
  Typography,
  IconButton,
} from "@material-ui/core";

import {
  Dashboard as DashboardIcon,
  Person as PersonIcon,
  Description as DescriptionIcon,
  Work as WorkIcon,
  Message as MessageIcon,
  Bookmark as BookmarkIcon,
  Settings as SettingsIcon,
  Help as HelpIcon,
  ExitToApp as ExitToAppIcon,
} from "@material-ui/icons";
import TimelineIcon from "@material-ui/icons/Timeline";
import { useHistory, useLocation } from "react-router-dom";
import CloseIcon from "@material-ui/icons/Close";
import DeleteIcon from "@material-ui/icons/Delete";
import ChangePassword from "../../views/pages/recruiter/changePassword/ChangePassword";
import LockOpenIcon from "@material-ui/icons/LockOpen";
import GroupIcon from "@material-ui/icons/Group";
import BookmarksIcon from "@material-ui/icons/Bookmarks";
import QuestionAnswerIcon from "@material-ui/icons/QuestionAnswer";
import ResponsiveLogoutDialog from "../modals/LogoutModal";
import DeleteJobModal from "../modals/DeleteJobModal";
import DeleteProfile from "../modals/DeleteProfile";
import LockIcon from "@material-ui/icons/Lock";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles((theme) => ({
  sidebar: {
    width: 290,
    backgroundColor: "#FFFFFF",
    boxSizing: "border-box",
    "@media(max-width:960px)": {
      display: "none",
    },
    borderTop: "2px solid #F4F4F4",
    padding: "0",
  },
  sideBarList: {
    // border: '1px solid red',
    padding: 0,
  },
  profile: {
    display: "flex",
    gap: "10px",
    backgroundImage: "url(images/profilebackground.png)", // Replace with your background image URL
    backgroundSize: "cover", // Adjust to 'contain' or 'cover' based on your preference
    backgroundPosition: "center", // Adjust to position the image as needed
    backgroundRepeat: "no-repeat",
    alignItems: "center",
    flexDirection: "column",
    paddingTop: "15px",
  },
  avatar: {
    width: "80px",
    height: "80px",
    objectFit: "contain",
    marginBottom: theme.spacing(1),
  },
  role: {
    color: "#FFFFFF",
  },
  status: {
    color: "#FFFFFF",
    display: "flex",
    alignItems: "center",
    marginBottom: theme.spacing(2),
    marginTop: "10px",
  },
  listItem: {
    display: "flex",
    gap: "20px",
    alignItems: "center",
    paddingLeft: "26px",
    paddingRight: "26px",
    paddingTop: "15px",
    paddingBottom: "15px",
    transition: "background-color 0.3s, color 0.3s",
    "&:hover": {
      backgroundColor: "#5E58E4",
      color: "white", // Change text color on hover
      "& $listItemIcon img": {
        filter: "brightness(0) invert(1)", // Change icon color to white on hover
      },
      "& $listItemText": {
        color: "white", // Ensure text color changes to white
      },
    },
  },
  listItemIcon: {
    height: "24px",
    width: "24px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    objectFit: "contain",
  },
  disabled: {
    color: "gray",
    cursor: "not-allowed",
  },
  listItemText: {
    fontSize: 16,
    fontWeight: "400",
    lineHeight: "21px",
    fontFamily: "Inter",
    color: "#1E252E",
  },
  selected: {
    backgroundColor: "#5E58E4",
    color: "#FFFFFF",
    fontSize: "16px",
    "& $listItemIcon img": {
      filter: "brightness(0) invert(1)", // Change icon color for selected item
    },
    "& $listItemText": {
      color: "#FFFFFF",
      fontWeight: "500",
      fontSize: "16px",
    },
  },
  subBox: {
    display: "flex",
    flexDirection: "column", // Arrange children in a column
    alignItems: "center", // Center items horizontally
    justifyContent: "center", // Center items vertically
    padding: "15px",
    gap: "15px",
  },
  image: {
    width: "81px",
    height: "81px",
  },
  dialogheading: {
    fontSize: "28px",
    fontWeight: "600",
    color: "#161E29",
  },
  dialogsubheading: {
    lineHeight: "22px",
  },
  btnBox: {
    display: "flex",
    gap: "20px",
  },
  btn1: {
    marginTop: "10px",
    width: "200px",
    height: "52px",
  },
  btn2: {
    marginTop: "10px",
    width: "200px",
    height: "52px",
    border: "1px solid #797979",
    backgroundColor: "#797979",
    "&:hover": {
      border: "1px solid #797979",
      boxShadow: "none",
      color: "#797979",
      backgroundColor: "#fff",
    },
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[900],
  },

  lockIcon: {
    color: "gray",
    marginLeft: "auto",
    marginRight: "10px",
  },
}));

const RecruiterSidebar = () => {
  const classes = useStyles();
  const history = useHistory();
  const location = useLocation(); // Get the current location
  const [open, setOpen] = useState(false);
  const [openChangePassword, setOpenChangePassword] = useState(false);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const { t } = useTranslation();

  const subscriptionStatus =
    sessionStorage.getItem("subscriptionStatus") === "ACTIVE";
  const handleListItemClick = (item) => {
    if (item.disabled) return; // Prevent action if disabled

    if (item.action) {
      item.action(); // Execute custom action if defined
    } else if (item.path) {
      history.push(item.path); // Navigate to path if defined
    }
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleLogOut = () => {
    history.push("/recruiterlandingpage");
  };

  const handleChangePassword = () => {
    setOpenChangePassword(true);
  };

  const handleCloseChangePassword = () => {
    setOpenChangePassword(false);
  };

  const [logoutModalOpen, setLogoutModalOpen] = useState(false); // State to manage DeleteJobModal open/close

  const handleLogout = () => {
    setLogoutModalOpen(true); // Open DeleteJobModal when "Cancel Plan" is clicked
  };
  const handleDelete = () => {
    setOpenDeleteModal(true);
  };
  const handleLogoutModalClose = () => {
    setLogoutModalOpen(false); // Close DeleteJobModal
  };
  const handleCloseModal = () => {
    setOpenDeleteModal(false); // Close the delete modal
  };

  const menuItems = [
    {
      text: t("recruiterMenu.dashboard"), // Translated text
      icon: "/images/DashboardIcon.svg",
      iconOnFocus: "/images/DashboardIcon.svg",
      path: "/recruiterDashboard",
      disabled: false,
    },
    {
      text: t("recruiterMenu.companyProfile"), // Translated text
      icon: "/images/ProfileBlackIcon.svg",
      iconOnFocus: "/images/ProfileBlackIcon.svg",
      path: "/CompanyProfile",
      disabled: false,
    },
    {
      text: t("recruiterMenu.postNewJob"), // Translated text
      icon: "/images/PostJobBlackIcon.svg",
      iconOnFocus: "/images/PostJobBlackIcon.svg",
      path: "/postNewJob",
      disabled: !subscriptionStatus,
    },
    {
      text: t("recruiterMenu.manageJobs"), // Translated text
      icon: "/images/ManageJobBlackIcon.svg",
      iconOnFocus: "/images/ManageJobBlackIcon.svg",
      path: "/ManageJob",
      disabled: !subscriptionStatus,
    },
    {
      text: t("recruiterMenu.allApplicants"), // Translated text
      icon: "/images/ApplicantBlackIcon.svg",
      iconOnFocus: "/images/ApplicantBlackIcon.svg",
      path: "/allApplicants",
      disabled: !subscriptionStatus,
    },
    {
      text: t("recruiterMenu.shortlistedResumes"), // Translated text
      icon: "/images/SavedBlackIcon.svg",
      iconOnFocus: "/images/SavedBlackIcon.svg",
      path: "/shortlisted-resume",
      disabled: !subscriptionStatus,
    },
    {
      text: t("recruiterMenu.subscriptions"), // Translated text
      icon: "/images/SubscriptionBlackIcon.svg",
      iconOnFocus: "/images/SubscriptionBlackIcon.svg",
      path: "/subscription",
      disabled: !subscriptionStatus,
    },
    {
      text: t("recruiterMenu.chats"), // Translated text
      icon: "/images/ChatBlackIcon.svg",
      iconOnFocus: "/images/ChatBlackIcon.svg",
      path: "/chats",
      disabled: !subscriptionStatus,
    },
    {
      text: t("recruiterMenu.changePassword"), // Translated text
      icon: "/images/PasswordBlackIcon.svg",
      iconOnFocus: "/images/PasswordBlackIcon.svg",
      action: handleChangePassword,
    },
    {
      text: t("recruiterMenu.logout"), // Translated text
      icon: "/images/LogoutBlackIcon.svg",
      iconOnFocus: "/images/LogoutBlackIcon.svg",
      action: handleLogout,
      disabled: false,
    },
    {
      text: t("recruiterMenu.deleteProfile"), // Translated text
      icon: "/images/DeleteBlackIcon.svg",
      iconOnFocus: "/images/DeleteBlackIcon.svg",
      action: handleDelete,
      disabled: false,
    },
  ];

  return (
    <div className={classes.sidebar}>
      <List className={classes.sideBarList}>
        {menuItems.map((item, index) => (
          <ListItem
            button
            key={index}
            disabled={item.disabled}
            className={`${classes.listItem} ${
              item.path && location.pathname.startsWith(item.path)
                ? classes.selected
                : ""
            }`}
            onClick={() => handleListItemClick(item)}
          >
            <ListItemIcon className={classes.listItemIcon}>
              <img
                src={
                  location.pathname.startsWith(item.path) && item.iconOnFocus
                    ? item.iconOnFocus
                    : item.icon
                }
                alt="itemIcon"
                style={{ objectFit: "contain" }}
              />
            </ListItemIcon>
            <ListItemText
              primary={item.text}
              classes={{
                primary: item.disabled
                  ? `${classes.listItemText} ${classes.disabled}`
                  : classes.listItemText,
              }}
            />

            {item.disabled && <LockIcon className={classes.lockIcon} />}
          </ListItem>
        ))}
      </List>

      <ResponsiveLogoutDialog
        open={logoutModalOpen}
        onClose={handleLogoutModalClose}
      />
      <DeleteProfile
        open={openDeleteModal}
        onClose={handleCloseModal}
        Img="./images/dustbin.png"
        message={t("confirmDeleteProfile")}
          buttonText1={t("no")}
          buttonText2={t("yes")}
      />
      <ChangePassword
        open={openChangePassword}
        onClose={handleCloseChangePassword}
        message="Your new Password must be different form previous used Password."
        modalTitle="Change Password"
        buttonText="Save"
      />
    </div>
  );
};

export default RecruiterSidebar;
