import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

// Import locale files
import enTranslation from './locales/en/translation.json';
import arTranslation from './locales/ar/translation.json';

const storedLanguage = sessionStorage.getItem('language'); 
const browserLanguage = navigator.language.split('-')[0];
const fallbackLanguage = storedLanguage || (['en', 'ar'].includes(browserLanguage) ? browserLanguage : 'en');

i18n
  .use(initReactI18next) 
  .init({
    fallbackLng: fallbackLanguage,
    supportedLngs: ['en', 'ar'],
    debug: false,
    interpolation: {
      escapeValue: false, 
    },
    resources: {
      en: { translation: enTranslation },
      ar: { translation: arTranslation },
    },
    react: {
      useSuspense: false, 
    },
  });

export default i18n;