import React, { createContext, useContext, useEffect, useState } from "react";
import axios from "axios";
import toast from "react-hot-toast";
import { getApiHandler, getDataHandlerWithToken } from "../config/service";
import { AuthContext } from "./Auth";

// Create context
export const JobContext = createContext();

export default function JobProvider(props) {
  const [jobList, setJobList] = useState([]);
  const [jobs, setJobs] = useState([]);
  const [saveData, setSaveData] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [totalData, setTotalData] = useState(0);
  const [totalPages, setTotalPages] = useState(1);
  const userData = sessionStorage.getItem("user");
  const userObject = userData ? JSON.parse(userData) : null;
  const [token, setToken] = useState(userObject?.token);
  const { isLogin } = useContext(AuthContext);
  const [currentPage, setCurrentPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [recommended, setRecommended] = useState(false);

  useEffect(() => {
    setToken(sessionStorage.getItem("token"));
  }, [isLogin]);

  const [filterValues, setFilterValues] = useState({
    jobTitle: "",
    location: "",
    industry: "",
    careerLevel: [],
    category: [],
    industry: [],
    industrySector: [],
    jobCategory: [],
    jobType: "",
    experience: "",
    salaryRange: "",
  });

  // Search filter state (job title and location)
  const [filter, setFilter] = useState({
    jobTitle: "",
    location: "",
    industry: "",
    industrySector: [],
    jobType: "",
    jobCategory: "",
  });

  useEffect(() => {
    // console.log("inside context filter checking", filter);
  }, [filter]);

  const fetchJobs = async () => {
    setIsLoading(true);
    try {
      // Create params only with values that are set
      const params = {
        limit: rowsPerPage,
        page: currentPage, // API expects 1-based index
      };
      let isFilterApplied = false;
      if (filter.jobTitle) {
        params.jobTitle = filter.jobTitle;
        isFilterApplied = true;
        console.log("Job title filter applied:", filter.jobTitle);
      }
      if (filter.location) {
        params.location = filter.location;
        isFilterApplied = true;
      }
      if (filter.industry) {
        params.industry = filter.industry;
        isFilterApplied = true;
      }
      if (filter.industrySector) {
        params.industrySector = filter.industrySector;
        isFilterApplied = true;
      }
      if (filter.jobCategory) {
        params.jobCategory = filter.jobCategory;
        isFilterApplied = true;
      }

      if (filterValues.jobType) {
        params.jobType = filterValues.jobType;
        isFilterApplied = true;
      }

      if (filterValues.experience) {
        let arr = filterValues.experience.split("-").map(Number);
        params.minExperience = arr[0];
        params.maxExperience = arr[1];
        isFilterApplied = true;
      }
      if (filterValues.salaryRange) {
        let arr = filterValues.salaryRange.split("-").map(Number);
        params.minSalary = arr[0];
        params.maxSalary = arr[1];
        isFilterApplied = true;
      }
      if (filterValues.industry.length > 0) {
        params.industry = filterValues.industry;
        isFilterApplied = true;
      }
      if (filterValues.industrySector.length > 0) {
        params.industrySector = filterValues.industrySector;
        isFilterApplied = true;
      }
      if (filterValues.jobCategory.length > 0) {
        params.jobCategory = filterValues.jobCategory;
        isFilterApplied = true;
      }

      let response;
      if (!token) {
        response = await getApiHandler("filterjobs", params);
      } else {
        response = await getDataHandlerWithToken("getRecommendedJobs", params);
      }

      if (response.responseCode === 200) {
        setRecommended(isFilterApplied);
        setJobList(response.result.docs);
        setTotalPages(response?.result?.pages);
        setTotalData(response?.result?.total || 0);
      } else {
        console.log("Failed to fetch jobs");
      }
    } catch (error) {
      console.log("Error fetching job listings", error);
    } finally {
      setIsLoading(false);
    }
  };

  const fetchLatestJobs = async () => {
    setIsLoading(true);
    try {
      const params = {
        jobTitle: filter.jobTitle,
        location: filter.location,
        industry: filter.industry,
      };

      const data = await getApiHandler("getLatestJobs", params);

      // console.log(data.result.docs);
      setJobs(data.result.docs);
    } catch (error) {
      console.log("Error fetching latest job listings");
    } finally {
      setIsLoading(false);
    }
  };

  // Function to handle input changes for title and location
  const handleSearchInputChange = (e) => {
    const { name, value } = e.target;
    setFilter((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  // Context data that will be provided to other components
  const contextData = {
    jobList,
    setJobList,
    isLoading,
    setIsLoading,
    fetchJobs,
    fetchLatestJobs,
    setJobs,
    setSaveData,
    filter,
    saveData,
    jobs,
    setFilter,
    handleSearchInputChange,
    totalData,
    setTotalData,
    totalPages,
    setTotalPages,
    filterValues,
    setFilterValues,
    currentPage,
    setCurrentPage,
    rowsPerPage,
    setRowsPerPage,
    recommended,
  };

  return (
    <JobContext.Provider value={contextData}>
      {props.children}
    </JobContext.Provider>
  );
}
