import axios from "axios";
import ApiConfig from "./apiconfig";
import toast from "react-hot-toast";

const Token = sessionStorage.getItem("token");

const user = sessionStorage.getItem("user");
let token = Token;
if (user) {
  try {
    const parsedUser = JSON.parse(user);
    token = parsedUser?.token || null; // Safely access the token
  } catch (error) {
    console.error("Error parsing user from sessionStorage:", error);
  }
} else {
  console.warn("No user found in sessionStorage");
}

// console.log("Koca: token in the sercvie file ", token);
export const postApiHandler = async (endPoint, body) => {
  try {
    const res = await axios({
      method: "POST",
      url: ApiConfig[endPoint],
      data: body,
    });
    if (res?.data?.responseCode === 200 || res.data?.responseCode === 201) {
      return res.data;
    } else {
      console.log("*******************");
      return res.data;
    }
  } catch (error) {
    // console.log("*******************", error?.response?.data);
    return error?.response?.data;
  }
};

export const postApiHandlerWithToken = async (endPoint, body) => {
  // console.log(token);
  try {
    const res = await axios({
      method: "POST",
      url: ApiConfig[endPoint],
      data: body,
      headers: {
        token: sessionStorage.getItem("token"),
      },
    });
    if (res?.data?.responseCode === 200 || res.data?.responseCode === 201) {
      return res.data;
    } else {
      return res.data;
    }
  } catch (error) {
    // console.log(error?.response?.data, "postApiHandlerWithToken");
    return error?.response?.data;
  }
};

export const postAIApiHandlerWithToken = async (endPoint, body) => {
  try {
    const res = await axios({
      method: "POST",
      url: ApiConfig[endPoint],
      data: body,
      headers: {
        token: sessionStorage.getItem("token"),
        "Content-Type": "multipart/form-data",
      },
    });
    if (res?.data?.responseCode === 200 || res.data?.responseCode === 201) {
      return res.data;
    } else {
      return res.data;
    }
  } catch (error) {
    // console.log(error?.response?.data, "postAIApiHandlerWithToken");
    return error?.response?.data;
  }
};
export const getApiHandlerWithToken = async (endPoint, params) => {
  const user = sessionStorage.getItem("user");
  let token = Token;
  // console.log(Token);
  if (user) {
    try {
      const parsedUser = JSON.parse(user);
      token = parsedUser?.token || null;
    } catch (error) {
      console.error("Error parsing user from sessionStorage:", error);
    }
  }

  if (!token) {
    console.error("No token found! Please provide a token.");
    return; // Exit the function if no token is found
  }

  // console.log("Token being used for the API call:", token);

  try {
    const res = await axios({
      method: "GET",
      url: ApiConfig[endPoint],
      params: params,
      headers: {
        token: sessionStorage.getItem("token"),
      },
    });

    if (res.data?.responseCode === 200 || res.data?.responseCode === 201) {
      return res.data;
    }
  } catch (error) {
    // console.log(`Error while calling API ${endPoint}:`, error);
    return error?.response?.data;
  }
};

export const getDataHandlerWithToken = async (endPoint, query, data) => {
  try {
    const res = await axios({
      method: "GET",
      url: ApiConfig[endPoint],
      headers: {
        token: sessionStorage.getItem("token"),
      },
      params: query,
      data: data,
    });
    if (res.data?.responseCode == 200 || res.data?.responseCode === 201) {
      return res.data;
    }
  } catch (error) {
    console.log(`Error while api ${endPoint} is calling`, error);
    return error?.response?.data;
  }
};
export const getApiHandler = async (endPoint, params) => {
  // alert("api")
  try {
    const res = await axios({
      method: "GET",
      url: ApiConfig[endPoint],
      params: params,
    });
    if (res?.data?.responseCode === 200 || res.data?.responseCode === 201) {
      return res.data;
    }
  } catch (error) {
    console.log(error?.response?.data?.responseMessage, "errorerror");
    // !(error?.response?.data?.responseMessage === "No Contact us data found") &&
    //   toast.error(
    //     error?.response?.data?.responseMessage || "Something went wrong"
    //   );
    return null;
  }
};
export const putApiHandlerWithToken = async (endPoint, params) => {
  try {
    const res = await axios({
      method: "PUT",
      url: ApiConfig[endPoint],
      params: params,
      headers: {
        token: sessionStorage.getItem("token"),
      },
    });
    if (res.data?.responseCode == 200 || res.data?.responseCode === 201) {
      return res.data;
    }
  } catch (error) {
    console.log(`Error while api ${endPoint} is calling`, error);
    return error?.response?.data;
  }
};

export const putDataHandlerWithToken = async (endPoint, body) => {
  try {
    const res = await axios({
      method: "PUT",
      url: ApiConfig[endPoint],
      data: body,
      headers: {
        token: sessionStorage.getItem("token"),
      },
    });

    if (res.data?.responseCode == 200 || res.data?.responseCode === 201) {
      return res.data;
    }
  } catch (error) {
    console.log(`Error while api ${endPoint} is calling`, error);
    return error?.response?.data;
  }
};

export const deleteApiHandlerWithToken = async (endPoint, body) => {
  try {
    const res = await axios({
      method: "DELETE",
      url: ApiConfig[endPoint],
      data: body,
      headers: {
        token: sessionStorage.getItem("token"),
      },
    });
    if (res.data?.responseCode == 200 || res.data?.responseCode === 201) {
      return res.data;
    }
  } catch (error) {
    console.log(`Error while api ${endPoint} is calling`, error);
    return error?.response?.data;
  }
};
export const deleteDataHandlerWithToken = async (endPoint, query) => {
  try {
    const res = await axios({
      method: "DELETE",
      url: ApiConfig[endPoint],
      headers: {
        token: sessionStorage.getItem("token"),
      },
      params: query,
    });
    if (res.data.responseCode === 200) {
      return res.data;
    } else {
      return res;
    }
  } catch (error) {
    console.log(error);
  }
};

export const postApiSocialHandler = async (endPoint, body) => {
  try {
    const res = await axios({
      method: "POST",
      url: ApiConfig[endPoint],
      data: body,
      headers: {
        "Content-Type": "application/json",
      },
    });
    if (res?.data?.responseCode === 200 || res.data?.responseCode === 201) {
      return res.data;
    }
  } catch (error) {
    console.log(error?.response?.data, "333333333333333333333333");
    return error?.response?.data;
  }
};

export const getDataHandler = async (endPoint, query, data) => {
  try {
    const res = await axios({
      method: "GET",
      url: ApiConfig[endPoint],

      params: query,
      data: data,
    });

    if (res?.data?.responseCode === 200) {
      return res.data;
    } else {
      toast.error(res?.data?.message);
      return res;
    }
  } catch (error) {
    if (error.response) {
      return error.response;
    } else {
      return error;
    }
  }
};

export const postDataHandlerWithToken = async (endPoint, data) => {
  try {
    const res = await axios({
      method: "POST",
      url: ApiConfig[endPoint],
      headers: {
        token: sessionStorage.getItem("token"),
      },
      data: data,
    });
    if (res?.data?.responseCode === 200) {
      return res.data;
    } else {
      return res;
    }
  } catch (error) {
    return error.response;
  }
};

export const putDataHandler = async (endPoint, data) => {
  try {
    const res = await axios({
      method: "POST",
      url: ApiConfig[endPoint],
      data: data,
    });
    if (res.data.responseCode === 200) {
      return res.data;
    } else {
      return res;
    }
  } catch (error) {
    console.log(error);
    if (error.response) {
      return error.response;
    }
  }
};

export const postDataHandler = async (endPoint, data) => {
  try {
    const res = await axios({
      method: "POST",
      url: ApiConfig[endPoint],
      data: data,
    });

    if (res?.data?.responseCode === 200) {
      return res.data;
    } else {
      toast.error(res?.data?.message);
      return res;
    }
  } catch (error) {
    if (error.response) {
      return error.response;
    } else {
      return error;
    }
  }
};
export const putDataHandlerWithTokenR = async (endPoint, data, params) => {
  try {
    const res = await axios({
      method: "PUT",
      url: ApiConfig[endPoint],
      headers: {
        token: sessionStorage.getItem("token"),
      },
      data: data,
      params: params,
    });
    if (res.data.responseCode === 200) {
      return res.data;
    } else {
      return res;
    }
  } catch (error) {
    console.log(error);
    if (error.response) {
      return error.response;
    }
  }
};
export const transliterateToLanguage = async (textOrArray, type = "default") => {
  const API_KEY = process.env.REACT_APP_OPEN_AI_TRANSLATER_API_KEY;
  console.log(textOrArray);
  console.log(API_KEY, "OpenAI API Key");

  const detectLanguage = (text) => {
    const arabicRegex = /[\u0600-\u06FF]/;
    return arabicRegex.test(text) ? "arabic" : "english";
  };

  const handleTranslationError = (language) => {
    return language === "arabic" ? "غير متوفر" : "NA";
  };

  // Function to check if a string is a date
  const isDate = (value) => {
    const dateRegex =
      /^\d{4}-\d{2}-\d{2}(T\d{2}:\d{2}:\d{2}(?:\.\d{3}Z)?)?$/; // ISO format check
    return dateRegex.test(value);
  };

  const translateText = async (text, type) => {
    if (!text || text.trim() === "") {
      return handleTranslationError();
    }
    // Return the value as-is if it's a date
    if (isDate(text)) {
      return text;
    }

    const language = detectLanguage(text);
    const targetLanguage = language === "english" ? "Arabic" : "English";

    try {
      const response = await fetch(
        "https://api.openai.com/v1/chat/completions",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${API_KEY}`,
          },
          body: JSON.stringify({
            model: "gpt-3.5-turbo",
            messages: [
              {
                role: "system",
                content:
                  type === "noun"
                    ? `You are an assistant that specializes in transliteration of proper nouns. Your task is to convert proper nouns in Arabic to their accurate English spelling and vice versa. Do not translate the name; instead, provide the exact transliterated spelling.`
                    : `You are a helpful assistant that translates text from ${
                        language === "english" ? "English" : "Arabic"
                      } to ${targetLanguage}.`,
              },
              {
                role: "user",
                content:
                  type === "noun"
                    ? `Transliterate the following name to ${targetLanguage}:\n\n${text}`
                    : `Translate the following text to ${targetLanguage}:\n\n${text}`,
              },
            ],
            max_tokens: 100,
            temperature: 0.3,
          }),
        }
      );

      const data = await response.json();

      if (data.choices && data.choices[0] && data.choices[0].message) {
        const translation = data.choices[0].message.content.trim();

        if (
          translation.startsWith("I'm sorry") ||
          translation
            .toLowerCase()
            .includes("does not have a specific meaning") ||
          translation.length === 0
        ) {
          return handleTranslationError();
        }

        return translation;
      } else {
        return handleTranslationError();
      }
    } catch (error) {
      console.error("Translation Error:", error);
      return handleTranslationError();
    }
  };

  const translateObjectArray = async (objectArray, type) => {
    return await Promise.all(
      objectArray.map(async (obj) => {
        const translatedObject = {};
        for (const key in obj) {
          if (Object.hasOwnProperty.call(obj, key)) {
            const value = obj[key];
            translatedObject[key] =
              typeof value === "string" && !isDate(value)
                ? await translateText(value, type)
                : value; // Skip date values
          }
        }
        return translatedObject;
      })
    );
  };

  if (Array.isArray(textOrArray)) {
    if (
      textOrArray.every(
        (item) => typeof item === "object" && !Array.isArray(item)
      )
    ) {
      return await translateObjectArray(textOrArray, type); // Array of objects
    } else {
      const translatedArray = await Promise.all(
        textOrArray.map((item) =>
          typeof item === "string" && !isDate(item)
            ? translateText(item, type)
            : item // Skip date values
        )
      );
      return translatedArray;
    }
  }

  return typeof textOrArray === "string" && !isDate(textOrArray)
    ? await translateText(textOrArray, type)
    : textOrArray; // Skip date values
};

