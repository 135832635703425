import React from 'react';
import { Dialog, DialogContent, DialogActions, Button, Typography, Box, makeStyles } from '@material-ui/core';
import typography from '../../theme/typography';
import ModalImg from '../../assets/ModalImg.png';
import { useHistory } from 'react-router-dom';

const useStyles = makeStyles(() => ({
    dialog:{
        //height:"349px",
       
        "& .MuiDialogContent-root:first-child":{
            paddingTop:"0px"
        }
    }
}
))


// ModalComponent to be used for displaying the modal
const CommonRecruiterModal = ({ 
    open, 
    onClose, 
    message, 
    buttonText, 
    onreset, 
    onClick1, 
    ButtonText1, 
    onClick2, 
    ButtonText2 
}) => {
    const classes = useStyles();
    const onbuttonClick = () => {
        if (onreset) onreset(); // Call onreset only if it is provided
        if (onClose) onClose(); // Call onClose only if it is provided
    }
    
    return (
        <Dialog open={open} onClose={onClose} className={classes.dialog} maxWidth="sm">
            <Box style={{ padding: '40px 50px' }}>
                <DialogContent style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', textAlign: 'center' }}>
                    <img src={ModalImg} alt="Modal" style={{ width: 'auto', height: '100%', objectFit: 'contain', marginBottom: '16px' }} />
                    <Typography style={{... typography.modalMessage}}>{message}</Typography>
                </DialogContent>
                <DialogActions style={{ justifyContent: 'center' }}>
                    {buttonText && ( // Conditionally render the first button
                        <Button variant="contained" color="secondary" style={{width: '50%'}} onClick={onbuttonClick}>{buttonText}</Button>
                    )}
                    {ButtonText1 && onClick1 && ( // Conditionally render the second button
                        <Button variant="contained" color="secondary" onClick={onClick1} >{ButtonText1}</Button>
                    )}
                    {ButtonText2 && onClick2 && ( // Conditionally render the third button
                        <Button variant="contained" color="secondary" onClick={onClick2}>{ButtonText2}</Button>
                    )}
                </DialogActions>
            </Box>
            <Box
        style={{
          position: 'absolute',
          bottom: -16,
          left: '50%',
          transform: 'translateX(-50%)',
          height: "16px",
          backgroundColor: "#5E58E4",
          width: "80%",
          borderBottomLeftRadius: "16px",
          borderBottomRightRadius: "16px",
        }}
      />
        </Dialog>
    );
}

export default CommonRecruiterModal;
