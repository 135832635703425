import {
  Box,
  Button,
  Divider,
  FormHelperText,
  InputAdornment,
  makeStyles,
  TextField,
  Typography,
  CircularProgress,
  IconButton,
} from "@material-ui/core";
import React, { useContext, useState } from "react";
import { Formik, Form } from "formik";
import * as yup from "yup";
import { useHistory } from "react-router-dom";
import { AuthContext } from "../../../../context/Auth";
import OTPFlow from "./OTPFlow";
import Popup from "../../../../component/modals/LoginModal";
import VisibilityIcon from "@material-ui/icons/Visibility";
import { postApiHandler } from "../../../../config/service";
import toast from "react-hot-toast";
import { useTranslation } from "react-i18next";

const mediaQueries = {
  small: "@media(max-width:768px)",
  extraSmall: "@media(max-width:430px)",
};

const useStyles = makeStyles((theme) => ({
  authContainer: {
    display: "flex",
    flexDirection: "row",
    "& .MuiOutlinedInput-notchedOutline": {
      borderColor: "#AFB0B6",
    },
  },
  formContainer: {
    borderRadius: "20px 0px 0px 20px",
    backgroundColor: "white",
    padding: theme.spacing(4),
    [mediaQueries.small]: {
      borderRadius: "20px",
      width: "40vw",
    },
    [mediaQueries.extraSmall]: {
      padding: theme.spacing(2),
      width: "60vw",
    },
    position: "relative",
  },
  imageSection: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    [mediaQueries.small]: {
      display: "none",
    },
  },
  image: {
    objectFit: "contain",
    borderRadius: "0px 20px 20px 0px",
    [mediaQueries.small]: {
      width: "100%",
      height: "100%",
    },
  },
  heading: {
    fontWeight: 700,
    fontSize: 24,
    fontFamily: "Inter",
    color: "#262626",
    [mediaQueries.small]: {
      fontSize: 18,
    },
  },
  btnBox: {
    marginTop: "30px",
    marginBottom: "10px",
  },
  btn: {
    backgroundColor: "#5E58E4",
    color: "#fff",
  },
  staticContent: {
    top: "52px",
    width: "25%",
    position: "absolute",
    color: "white",
    [mediaQueries.small]: {
      top: "132px",
      width: "21%",
    },
  },
  staticheading1: {
    fontWeight: 400,
    fontSize: 20,
    lineHeight: "24px",
    color: "#FFFFFF",
    fontFamily: "Inter",
    marginBottom: theme.spacing(1),
  },
  staticheading2: {
    fontWeight: 700,
    fontSize: 20,
    lineHeight: "24px",
    color: "#FFFFFF",
    fontFamily: "Inter",
  },
  textBox: {
    marginTop: "18px",
  },
}));

const ResetPassword = ({ open, onClose, handletestClick, email }) => {
  const auth = useContext(AuthContext);
  const classes = useStyles();  
  const history = useHistory();
  const [isLoading, setIsLoading] = useState(false);
  const [openOTP, setOpenOTP] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const { t } = useTranslation();
  const resetPasswordValidation = t('resetPasswordSeeker.validation', { returnObjects: true });
  const resetPasswordSeeker = t("resetPasswordSeeker", { returnObjects: true });



  const handleOTPOpen = () => {
    onClose();
    handletestClick();
  };

  const handleOTPClose = () => setOpenOTP(false);

  const formInitialSchema = { password: "", cpassword: "" };

  const formValidationSchema = yup.object().shape({
    password: yup
      .string()
      .required(resetPasswordValidation.passwordRequired)
      .min(8, resetPasswordValidation.passwordMinLength),
    cpassword: yup
      .string()
      .oneOf(
        [yup.ref("password"), null],
        resetPasswordValidation.cpasswordMismatch
      )
      .required(resetPasswordValidation.cpasswordRequired),
  });

  const handleFormSubmit = async (values, { resetForm }) => {
    console.log(values, "values");
    const data = {
      newPassword: values.password,
      email: email,
    };
    setIsLoading(true);

    try {
      const response = await postApiHandler("resetPasswordSeeker", data);
      if (response?.responseCode === 200) {
        resetForm();
        setIsLoading(false);

        toast.success(
          response?.responseMessage || "Password reset successful."
        );
        onClose();
      } else {
        toast.error(
          response?.responseMessage || "Something went wrong. Please try again."
        );
      }
    } catch (error) {
      toast.error(
        error?.response?.responseMessage || "Failed to reset password."
      );
      setIsLoading(false);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <>
      <Popup open={open} handleClose={onClose}>
        <Box className={classes.authContainer}>
          <Box className={classes.formContainer}>
            <Typography variant="h6" align="left" className={classes.heading}>
              {resetPasswordSeeker.resetPassword}
            </Typography>
            <Box my={2}>
              <Typography variant="body2">
                {resetPasswordSeeker.resetPasswordInstructions}
              </Typography>
            </Box>

            <Formik
              initialValues={formInitialSchema}
              validationSchema={formValidationSchema}
              onSubmit={handleFormSubmit}
            >
              {({
                errors,
                handleBlur,
                handleChange,
                handleSubmit,
                touched,
                values,
              }) => (
                <Form onSubmit={handleSubmit}>
                  <Box className={classes.textBox}>
                    <TextField
                      fullWidth
                      variant="outlined"
                      placeholder={resetPasswordSeeker.enterNewPassword}
                      type={showPassword ? "text" : "password"}
                      name="password"
                      id="password"
                      inputProps={{
                        maxLength: 62,
                        onCopy: (e) => e.preventDefault(), // Prevent copy
                        onPaste: (e) => e.preventDefault(), // Prevent paste
                      }}
                      value={values.password}
                      error={Boolean(touched.password && errors.password)}
                      onBlur={handleBlur}
                      onChange={handleChange}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <Box display="flex" alignItems="center">
                              <img src="/images/lock1.svg" alt="lock-icon" />
                              <Divider
                                orientation="vertical"
                                flexItem
                                style={{ height: "34px", margin: "0 10px" }}
                              />
                            </Box>
                          </InputAdornment>
                        ),
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton
                              onClick={() => setShowPassword(!showPassword)}
                              edge="end"
                            >
                              <Box>
                                {showPassword ? (
                                  // <HiEye className={classes.iconClass1} />
                                  <VisibilityIcon
                                    className={classes.iconClass1}
                                    style={{ width: "18px", height: "18px" }}
                                  />
                                ) : (
                                  <img
                                    src="/images/eyeP.svg"
                                    style={{
                                      width: "18px",
                                      height: "18px",
                                      objectFit: "contain",
                                    }}
                                  />
                                )}
                              </Box>
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                    />
                    <FormHelperText error>
                      {touched.password && errors.password}
                    </FormHelperText>
                  </Box>
                  <Box className={classes.textBox}>
                    <TextField
                      fullWidth
                      variant="outlined"
                      placeholder={resetPasswordSeeker.confirmNewPassword}
                      type={showConfirmPassword ? "text" : "password"}
                      name="cpassword"
                      id="cpassword"
                      inputProps={{
                        maxLength: 62,
                        onCopy: (e) => e.preventDefault(), // Prevent copy
                        onPaste: (e) => e.preventDefault(), // Prevent paste
                      }}
                      value={values.cpassword}
                      error={Boolean(touched.cpassword && errors.cpassword)}
                      onBlur={handleBlur}
                      onChange={handleChange}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <Box display="flex" alignItems="center">
                              <img src="/images/lock1.svg" alt="lock-icon" />
                              <Divider
                                orientation="vertical"
                                flexItem
                                style={{ height: "34px", margin: "0 10px" }}
                              />
                            </Box>
                          </InputAdornment>
                        ),
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton
                              onClick={() =>
                                setShowConfirmPassword(!showConfirmPassword)
                              }
                              edge="end"
                            >
                              <Box>
                                {showConfirmPassword ? (
                                  // <HiEye className={classes.iconClass1} />
                                  <VisibilityIcon
                                    className={classes.iconClass1}
                                    style={{ width: "18px", height: "18px" }}
                                  />
                                ) : (
                                  <img
                                    src="/images/eyeP.svg"
                                    style={{
                                      width: "18px",
                                      height: "18px",
                                      objectFit: "contain",
                                    }}
                                  />
                                )}
                              </Box>
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                    />
                    <FormHelperText error>
                      {touched.cpassword && errors.cpassword}
                    </FormHelperText>
                  </Box>

                  <Box className={classes.btnBox}>
                    <Button
                      className={classes.btn}
                      variant="contained"
                      color="secondary"
                      fullWidth
                      type="submit"
                      disabled={isLoading}
                    >
                      {isLoading ? (
                        <CircularProgress size={24} color="inherit" />
                      ) : (
                        resetPasswordSeeker.submit
                      )}
                    </Button>
                  </Box>
                </Form>
              )}
            </Formik>
          </Box>
          <Box className={classes.imageSection}>
            <img
              src="images/otp.png"
              alt="Illustration"
              className={classes.image}
            />
            <Box className={classes.staticContent}>
              <p className={classes.staticheading1}>
                {resetPasswordSeeker.veryGoodWorks}
              </p>
              <p className={classes.staticheading2}>
                {resetPasswordSeeker.resetPasswordHeading}
              </p>
            </Box>
          </Box>
        </Box>
      </Popup>
      <OTPFlow open={openOTP} onClose={handleOTPClose} />
    </>
  );
};

export default ResetPassword;
