import React, { useContext, useState } from "react";
import {
  Toolbar,
  IconButton,
  Typography,
  Drawer,
  List,
  ListItem,
  ListItemText,
  Box,
  Button,
  makeStyles,
  ListItemIcon,
} from "@material-ui/core";
import MenuIcon from "@material-ui/icons/Menu";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import ExpandLessIcon from "@material-ui/icons/ExpandLess";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { useHistory, useLocation } from "react-router-dom";
import MegaMenu from "../MegaMenu";
import Logo from "../../assets/logoipsum.svg";
import RecruiterLogin from "../../views/pages/recruiter/recruiterAuth/RecruiterLogin";
import RecruiterForgot from "../../views/pages/recruiter/recruiterAuth/RecruiterForgot";
import Otp from "../../views/pages/recruiter/recruiterAuth/RecruiterOtp";
import { RecruiterAuthContext } from "../../context/recruiterAuth";
import RecruiterResetPassword from "../../views/pages/recruiter/recruiterAuth/RecruiterResetPassword";
import RecruiterOtp from "../../views/pages/recruiter/recruiterAuth/RecruiterOtp";
import toast from "react-hot-toast";
import { useTranslation } from "react-i18next";
import {
    MenuItem,
    Select,
    FormControl,
    InputLabel,
    useTheme,
  } from "@mui/material";
const useStyles = makeStyles((theme) => ({
  menuItems: {
    cursor: "pointer",
    display: "flex",
    justifyContent: "flex-end",
    gap: "32px",
    alignItems: "center",
    position: "relative",
    "@media (max-width:1024px)": {
      gap: "10px",
    },
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
      alignItems: "center",
      gap: "15px",
    },
  },
  textStyle: {
    color: "#000000CC",
    fontWeight: "400",
    lineHeight: "21px",
  },
  menuBtn: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    fontSize: "18px",
    fontWeight: "500",
    "@media (max-width:1024px)": {
      fontWeight: "600",
    },
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      marginTop: "16px",
    },
  },
  drawerList: {
    width: "100%",
    height: "100%",
  },
  drawerListItem: {
    padding: "16px 24px",
    borderBottom: `1px solid ${theme.palette.divider}`,
    "&:hover": {
      backgroundColor: theme.palette.action.hover,
    },
  },
  drawerListItemText: {
    fontSize: "18px",
    fontWeight: "400",
    color: "#000000CC",
  },
  navbar: {
    padding: "20px 0px 20px 0px",
    paddingLeft: "13%",
    paddingRight: "13%",
    boxShadow: "0px 4px 4px 0px #0000000D",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    [theme.breakpoints.down("sm")]: {
      paddingLeft: "10px",
      paddingRight: "10px",
      flexDirection: "column",
      alignItems: "center",
    },
    "@media(max-width:768px)": {
      padding: "10px 0px 10px 0px",
      paddingLeft: "16px",
      paddingRight: "16px",
      flexDirection: "column",
      alignItems: "center",
    },
    "@media(min-width:769px) and (max-width:1024px) ": {
      paddingLeft: "9%",
      paddingRight: "9%",
    },
  },
  toolbar: {
    paddingLeft: "0px",
    paddingRight: "0px",
    width: "100%",
    display: "flex",
    // justifyContent: 'space-between',
    alignItems: "center",
  },
  iconColor: {
    color: "#000000CC",
    opacity: "0.9",
  },
  logo: {
    maxWidth: "200px",

    display: "block",
    cursor: "pointer",
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
    [theme.breakpoints.up("md")]: {},
  },
  list: {
    padding: 0,
    "& .MuiList-padding": {
      padding: 0,
    },
  },
  listItem: {
    display: "flex",
    alignItems: "center",

    gap: "20px",
    padding: "16px",
    paddingLeft: "30px",
    paddingRight: "30px",
    transition: "background-color 0.3s",
    transition: "background-color 0.3s, color 0.3s", // Add color transition
    "&:hover": {
      backgroundColor: "#5E58E4",
      color: "white", // Change text color on hover
      "& $listItemIcon img": {
        filter: "brightness(0) invert(1)", // Change icon color to white on hover
      },
      "& $listItemText": {
        color: "white", // Ensure text color changes to white
      },
    },
  },
  listItemIcon: {
    height: "24px",
    width: "24px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    objectFit: "contain",
  },
  listItemText: {
    fontSize: 16,
    fontWeight: "400",
    lineHeight: "19px",
    fontFamily: "Inter",
    color: "#1E252E",
  },
  selected: {
    backgroundColor: "#5E58E4",
    color: "#FFFFFF",
    fontSize: "16px",
    "& $listItemIcon img": {
      filter: "brightness(0) invert(1)", // Change icon color for selected item
    },
    "& $listItemText": {
      color: "#FFFFFF",
      fontWeight: "500",
      fontSize: "16px",
    },
  },
  drawerList: {
    width: 250,
    height: "100%",
  },
  drawerHeader: {
    padding: "16px 24px",
    fontSize: "20px",
    fontWeight: "bold",
    borderBottom: `1px solid ${theme.palette.divider}`,
  },
}));

const BeforeLoginHeader = () => {
  const history = useHistory();
  const classes = useStyles();
  const { recruiterLoggedIn } = useContext(RecruiterAuthContext);
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [isMegaMenuOpen, setIsMegaMenuOpen] = useState(false);
  const [open, setOpen] = React.useState(false);
  const [openForgot, setOpenForgot] = React.useState(false);
  const [openOtp, setOpenOtp] = React.useState(false);
  const [hoveredIndex, setHoveredIndex] = useState(null);
  const location = useLocation();
  const { isRecruiterLoggedIn } = useContext(RecruiterAuthContext);
  const { t, i18n } = useTranslation();
  const [openResetPassword, setOpenResetPassword] = React.useState(false);
  const [redirectPath, setRedirectPath] = useState();

  const handleOTPOpen = () => {
    setOpenOtp(true);
    setOpenForgot(false);
  };
  const handleResetOpen = () => {
    setOpenResetPassword(true);
    setOpenOtp(false);
    setOpenForgot(false);
  };

  const handleResetClose = () => setOpenResetPassword(false);

  const handleOpenLogin = () => {
    setOpen(true);
  };
  const handleDrawerToggle = () => {
    setDrawerOpen(!drawerOpen);
  };

  const toggleMegaMenu = () => {
    setIsMegaMenuOpen(!isMegaMenuOpen);
  };

  const handleClick = () => {
    history.push("/");
  };

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => setOpen(false);

  const handleForgotOpen = () => {
    setOpenForgot(true);
    setOpen(false);
  };
  const handleOtpClose = () => {
    setOpenOtp(false);
    setOpenForgot(false);
  };
  const handleForgotClose = () => setOpenForgot(false);

  const isMobile = useMediaQuery((theme) => theme.breakpoints.down("sm"));

  const handleClickPostJob = () => {
    setRedirectPath("/postNewJob");
    if (recruiterLoggedIn) {
      history.push("/postNewJob");
    } else {
      handleOpenLogin(); // Assuming this function opens a login modal or page
    }
  };

  const handleNavigation = (text) => {
    console.log(text)
    switch (text) {
      case "findJobs":
        history.push("/job-search");
        break;
      case "postJobs":
        handleClickPostJob();

        break;
      case "pricing":
        history.push("/pricing");

        break;
      case "contactUs":
        history.push("/contact-us");
        break;
      default:
        break;
    }
  };

  const handleLogoClick = () => {
    history.push("/recruiterlandingpage");
  };
  const handleOnClick = () => {
    history.push("/Companyregistration");
  };
  const handleItemClick = (path) => {
    history.push(path);
  };
  const menuItems = [
    {
      text: "Find Jobs",
      icon: "/images/icons8-find.svg",
      iconOnFocus: "/images/icons8-find.svg",
      path: "/job-search",
    },
    {
      text: "Post Jobs",
      icon: "/images/PostJobBlackIcon.svg",
      iconOnFocus: "/images/PostJobBlackIcon.svg",
      onClick: handleClickPostJob,
    },
    {
      text: "Pricing",
      icon: "/images/PostJobBlackIcon.svg",
      iconOnFocus: "/images/PostJobBlackIcon.svg",
      path: "/pricing",
    },
    {
      text: "Contact Us",
      icon: "/images/ApplicantBlackIcon.svg",
      iconOnFocus: "/images/ApplicantBlackIcon.svg",
      path: "/contact-us",
    },
    {
      text: "Sign Up",
      icon: "/images/ProfileBlackIcon.svg",
      iconOnFocus: "/images/ProfileBlackIcon.svg",
      path: "/Companyregistration",
    },
    {
      text: "Log In",
      icon: "/images/LogoutBlackIcon.svg",
      iconOnFocus: "/images/LogoutBlackIcon.svg",
      onClick: handleOpen,
    },
  ];
  const handleListItemClick = (path) => {
    history.push(path);
  };
  const handleChange = async (event) => {
    const language = event.target.value;
    sessionStorage.setItem("language", language);
    await i18n.changeLanguage(language);
  };
  const drawerList = (
    <Box className={classes.drawerList}>
      {/* Drawer Header with Logo */}
      <Box className={classes.drawerHeader}>
        <div className={classes.logoContainer} onClick={handleLogoClick}>
          <img src={Logo} alt="Logo" className={classes.logoImage} />
          {/* <Typography variant="h6" className={classes.logoText}>
                        JobPortal
                    </Typography> */}
        </div>
      </Box>

      <Box
        className={classes.drawerList}
        role="presentation"
        onClick={handleDrawerToggle}
        onKeyDown={handleDrawerToggle}
      >
        {/* <List className={classes.list}>
                {[
                    {
                        text: "Find Jobs",
                        path: "/job-search",
                    },
                    {
                        text: "Post Jobs",
                        onClick: handleClickPostJob,
                    },
                    {
                        text: "Pricing",
                        path: "/pricing",
                    },
                    {
                        text: "Contact Us",
                        path: "/contact-us",
                    },
                    {
                        text: "Sign Up",
                        path: "/Companyregistration",
                    },
                    {
                        text: "Log In",
                        onClick: handleOpen,
                    },
                ].map((item, index) => {
                    const isSelected = location.pathname === item.path; // Check if the item is selected
                    const isHovered = hoveredIndex === index && !isSelected; // Apply hover styles only if not selected

                    return (
                        <ListItem
                            button
                            key={index}
                            className={`${classes.listItem} ${isSelected ? classes.selected : ""
                                } ${isHovered ? classes.hovered : ""}`}
                            onClick={() =>
                                item.path ? handleItemClick(item.path) : item.onClick && item.onClick()
                            }
                            onMouseEnter={() => setHoveredIndex(index)} // Set hover index on mouse enter
                            onMouseLeave={() => setHoveredIndex(null)} // Clear hover index on mouse leave
                        >
                            <ListItemText classes={{ primary: classes.listItemText }}>
                                {item.text}
                            </ListItemText>
                        </ListItem>
                    );
                })}
            </List> */}

        <List className={classes.sideBarList}>
          {menuItems.map((item, index) => (
            <ListItem
              button
              key={index}
              className={`${classes.listItem} ${
                item.path && location.pathname === item.path
                  ? classes.selected
                  : ""
              }`}
              onClick={() =>
                item.path ? handleListItemClick(item.path) : item.onClick?.()
              }
            >
              <ListItemIcon className={classes.listItemIcon}>
                <img
                  src={
                    location.pathname === item.path && item.iconOnFocus
                      ? item.iconOnFocus
                      : item.icon
                  }
                  alt="itemIcon"
                  style={{ objectFit: "contain" }}
                />
              </ListItemIcon>
              <ListItemText
                primary={item.text}
                classes={{ primary: classes.listItemText }}
              />
            </ListItem>
          ))}
        </List>
      </Box>
    </Box>
  );

  return (
    <>
      <Box className={classes.navbar}>
        <Toolbar className={classes.toolbar}>
          {isMobile && (
            <IconButton
              edge="start"
              color="inherit"
              aria-label="menu"
              onClick={handleDrawerToggle}
            >
              <MenuIcon />
            </IconButton>
          )}
          <Box onClick={handleLogoClick}>
            <img
              src={Logo}
              alt="logo"
              style={{ width: "235px", height: "60px", marginLeft: "10px" }}
            />
          </Box>
          {!isMobile && (
            <Box className={classes.menuItems} flexGrow={1}>
              {["findJobs", "postJobs", "pricing", "contactUs"].map((key) => (
                <Box
                  display="flex"
                  alignItems="center"
                  key={key}
                  onClick={() =>
                    handleNavigation(key)
                  }
                  sx={{ cursor: "pointer" }}
                >
                  <Typography
                    variant="h4"
                    display="inline"
                    style={{ color: "#000000CC" }}
                  >
                    {t(`employerHeaderNavigation.${key}`)}
                  </Typography>
                  {key === "Browse Jobs" &&
                    (isMegaMenuOpen ? (
                      <ExpandLessIcon className={classes.iconColor} />
                    ) : (
                      <ExpandMoreIcon className={classes.iconColor} />
                    ))}
                </Box>
              ))}
              <Button
                variant="contained"
                color="secondary"
                className={classes.menuBtn}
                onClick={handleOpen}
              >
                {t("employerHeaderNavigation.signInRegister")}
              </Button>
              <FormControl sx={{ minWidth: 120 }}>
                {/* Select Component */}
                <Select
                  value={i18n.language}
                  onChange={handleChange}
                  sx={{
                    minWidth: 120,
                    borderRadius: "10px",
                    backgroundColor: "#fff",
                    // color: theme.palette.text.primary,
                    "& .MuiSelect-icon": {
                      color: "#5E58E4",
                    },
                    "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                      borderColor: "#5E58E4",
                      "&:hover .MuiOutlinedInput-notchedOutline": {
                        borderColor: "#5E58E4",
                      },
                    },
                  }}
                >
                  <MenuItem value="en">English</MenuItem>
                  <MenuItem value="ar">عربي</MenuItem>
                </Select>
              </FormControl>
              <MegaMenu isOpen={isMegaMenuOpen} />
            </Box>
          )}
        </Toolbar>
        <Drawer
          anchor="left"
          open={isMobile ? drawerOpen : false}
          onClose={handleDrawerToggle}
        >
          {drawerList}
        </Drawer>
      </Box>
      <RecruiterResetPassword
        open={openResetPassword}
        onClose={handleResetClose}
      />
      <RecruiterLogin
        open={open}
        onClose={handleClose}
        handleForgotClick={handleForgotOpen}
        redirectPath={redirectPath}
        setRedirectPath={setRedirectPath}
      />
      <RecruiterForgot
        open={openForgot}
        onClose={handleForgotClose}
        handleOTPClick={handleOTPOpen}
      />
      <RecruiterOtp
        open={openOtp}
        onClose={handleOtpClose}
        handleResetClick={handleResetOpen}
      />
    </>
  );
};

export default BeforeLoginHeader;
