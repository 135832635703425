import React, { useState, useContext } from "react";
import {
  Box,
  Typography,
  Link,
  Container,
  IconButton,
  Divider,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { AiFillHome } from "react-icons/ai";
import { styled } from "@material-ui/core/styles";
import Logo from "../assets/logoipsum.svg";
import { useHistory } from "react-router-dom";
import Login from "../views/pages/jobSeeker/Auth/Login";
import SignUp from "../views/pages/jobSeeker/Auth/SignUp";
import Forgot from "../views/pages/jobSeeker/Auth/Forgot";
import OTPFlow from "../views/pages/jobSeeker/Auth/OTPFlow";
import { AuthContext } from "../context/Auth";
import { motion } from "framer-motion";
import ResetPassword from "../views/pages/jobSeeker/Auth/ResetPassword";
import ResetOTPFlow from "../views/pages/jobSeeker/Auth/ResetOTPFlow";
import Success from "../views/pages/jobSeeker/Auth/CompleteProfile";
import RecruiterOtp from "../views/pages/recruiter/recruiterAuth/RecruiterOtp";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles((theme) => ({
  footer: {
    backgroundColor: "#fffff",
    color: "#fff",
    paddingTop: "40px",
    paddingLeft: "14%",
    paddingRight: "14.5%",

    "@media (max-width:1440px)": {
      paddingLeft: "15%",
      paddingRight: "15%",
    },
    "@media (max-width:1024px)": {
      paddingLeft: "8.2%",
      paddingRight: "7.9%",
    },
    "@media (max-width: 768px)": {
      paddingLeft: "7.9%",
      paddingRight: "7.6%",
    },
    "@media (max-width: 480px)": {
      paddingLeft: "7.6%",
      paddingRight: "7.3%",
    },
  },

  mainbox: {
    display: "flex",
    gap: "68px",
    "@media (max-width: 1610px)": {
      gap: "50px",
    },
    "@media (max-width:1540px)": {
      gap: "40px",
    },
    "@media (max-width:1024px)": {
      gap: "10px",
    },
    "@media (max-width: 768px)": {
      flexWrap: "wrap",
      gap: "16px",
    },
    "@media (max-width:480px)": {
      flexWrap: "wrap",
      gap: "11px",
    },

    "& h6": {
      lineHeight: "20px",
    },
    "& h4": {
      fontWeight: "600",
    },
  },

  icons: {
    marginTop: "20px",
    display: "flex",
    gap: "10px",
    [theme.breakpoints.down("md")]: {
      flexWrap: "wrap",
    },
  },

  column: {
    width: "130px",
    padding: theme.spacing(1),
    "@media (max-width: 1610px)": {
      width: "140px",
    },
    "@media (max-width: 768px)": {
      width: "105px",
    },
    "@media (max-width: 480px)": {
      width: "140px",
    },
    "@media (max-width: 390px)": {
      width: "120px",
    },
  },
  column1: {
    width: "230px",
    padding: theme.spacing(1),
    "@media (max-width: 1610px)": {
      width: "215px",
    },
    "@media (max-width: 480px)": {
      width: "165px",
    },
    "@media (max-width: 390px)": {
      width: "155px",
    },
  },
  column5: {
    width: "267px",
    // marginRight: '42px',
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    padding: theme.spacing(1),
    [theme.breakpoints.down("xs")]: {
      width: "65%",
    },
  },
  heading: {
    marginBottom: theme.spacing(3),
    color: "#000000",
    fontWeight: "600",
    whiteSpace: "nowrap",
    "@media (max-width: 390px)": {
      marginBottom: theme.spacing(2),
    },
  },
  link: {
    display: "block",
    marginBottom: theme.spacing(2),
    color: "black",
    textDecoration: "none",
    color: "#000000CC",
    fontWeight: 400,
    fontSize: 14,
    lineHeight: "14px",
    fontFamily: "Inter",
    textDecoration: "none !important",
    cursor: "pointer",
    whiteSpace: "nowrap",
    "@media (max-width:1024px)": {
      fontSize: 12,
      lineHeight: "14px",
    },
  },

  logo: {
    height: 40,
    marginBottom: theme.spacing(2),
  },
  divider: {
    width: "97%",
    marginLeft: "6px",
    marginTop: "25px",
    "@media (max-width:1024px)": {
      width: "92.5%",
    },
    "@media (max-width: 768px)": {
      width: "97%",
    },
    "@media (max-width: 480px)": {
      width: "97%",
    },
  },
  lowerbox: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    flexWrap: "wrap",
    // paddingLeft: '14%',
    // paddingRight: '14%',

    // '@media (max-width:1440px)': {
    //   paddingLeft: '10%',
    //   paddingRight: '10%',
    // },
    // '@media (max-width:1024px)': {
    //   paddingLeft: '8.2%',
    //   paddingRight: '7.9%',
    // },
    // '@media (max-width: 768px)': {
    //   paddingLeft: '7.9%',
    //   paddingRight: '7.6%',
    // },
    // '@media (max-width: 480px)': {
    //   paddingLeft: '7.6%',
    //   paddingRight: '7.3%',
    // },
    [theme.breakpoints.down("sx")]: {
      flexDirection: "column",
      textAlign: "center",
    },
  },
  lowerbox1: {
    display: "flex",
    gap: "22px",
    [theme.breakpoints.down("md")]: {
      display: "flex",
      gap: "20px",
    },
    [theme.breakpoints.down("sm")]: {
      display: "flex",
      gap: "20px",
      justifyContent: "space-between",
    },
    [theme.breakpoints.down("xs")]: {
      marginTop: "10px",
      display: "flex",
      gap: "20px",
    },
  },
  link1: {
    color: "#000000CC",
    fontWeight: 400,
    fontSize: 12,
    lineHeight: "14px",
    fontFamily: "Inter",
    textDecoration: "none",
    margin: 0,
    "&:hover": {
      textDecoration: "none",
    },
  },
  logo: {
    height: "60px",
    width: "60px",
    border: "1px solid #5E58E4",
    borderRadius: "50px",
    background:
      "radial-gradient(50% 50% at 50% 50%,  #5E58E4 50%, #5E58E4 100%)",
    color: "white",
    marginRight: "20px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    textAlign: "center",
  },
  imageBox: {
    marginBottom: "16px",
  },
  imageStyle: {
    "@media (max-width: 1024px)": {
      width: "100%",
    },
    "@media (max-width: 768px)": {
      width: "100%",
    },
    "@media (max-width: 480px)": {
      width: "100%",
    },
  },
}));

const Footer = () => {
  const classes = useStyles();
  const history = useHistory();
  const [email, setEmail] = useState("");
  const { isLogin } = useContext(AuthContext);
  const [open, setOpen] = React.useState(false);
  const [opensignup, setOpenSignup] = React.useState(false);
  const [openForgot, setOpenForgot] = React.useState(false);
  const [openOTP, setOpenOTP] = React.useState(false);

  const [openSuccess, setOpenSuccess] = React.useState(false);

  const [openReset, setOpenReset] = React.useState(false);

  const handleResetOpen = () => {
    setOpenReset(true);
    setOpenResetOTP(false);
  };

  const handleResetClose = () => setOpenReset(false);
  const [openResetOTP, setOpenResetOTP] = React.useState(false);
  const handleResetOTPOpen = () => {
    setOpenOTP(true);
    setOpenForgot(false);
  };

  const handleResetOTPClose = () => setOpenResetOTP(false);
  const fadeInUp = {
    hidden: { opacity: 0, y: 50 },
    visible: {
      opacity: 1,
      y: 0,
      transition: { duration: 0.6, ease: "easeOut" },
    },
  };

  const fadeInLeft = {
    hidden: { opacity: 0, x: -50 },
    visible: {
      opacity: 1,
      x: 0,
      transition: { duration: 0.6, ease: "easeOut" },
    },
  };

  const fadeInRight = {
    hidden: { opacity: 0, x: 50 },
    visible: {
      opacity: 1,
      x: 0,
      transition: { duration: 0.6, ease: "easeOut" },
    },
  };
  const grow = {
    hidden: { opacity: 0, scale: 0.5 },
    visible: {
      opacity: 1,
      scale: 1,
      transition: { duration: 0.6, ease: "easeOut" },
    },
  };

  const handleOpen = () => {
    setOpenSignup(false);
    setOpen(true);
  };
  const handleClose = () => setOpen(false);
  const handleSignupOpen = () => {
    setOpenSignup(true);
    setOpen(false);
  };

  const handleSignupClose = () => setOpenSignup(false);

  const handleForgotOpen = () => {
    setOpenForgot(true);
    setOpen(false);
  };

  const handleForgotClose = () => setOpenForgot(false);

  const handleOTPOpen = () => {
    setOpenOTP(true);
    setOpenForgot(false);
  };

  const handleOTPClose = () => setOpenOTP(false);

  const handScuccessClick = () => {
    setOpenOTP(false);
    setOpenSuccess(true);
  };

  const handleSuccessClose = () => setOpenSuccess(false);

  const handleCardClick = () => {
    if (isLogin) {
      history.push("/job-search");
    } else {
      console.log("user please login");
      handleSignupOpen();
    }
  };
  const { t } = useTranslation();
  return (
    <>
      <Box className={classes.footer}>
        <Box className={classes.mainbox}>
          <motion.div
            initial="hidden"
            whileInView="visible"
            viewport={{ once: false, amount: 0.3 }}
          >
            <Box className={classes.column1}>
              <Box className={classes.imageBox}>
                <img src={Logo} alt="logo" className={classes.imageStyle} />
              </Box>
              <Typography variant="h6">{t("footer.aboutUs")}</Typography>

              <Box className={classes.icons}>
                <a
                  href="https://www.facebook.com"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img
                    src="/images/facebook.png"
                    alt="Facebook"
                    height={20}
                    width={20}
                  />
                </a>
                <a
                  href="https://www.twitter.com"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img
                    src="/images/twitter.png"
                    alt="Twitter"
                    height={20}
                    width={20}
                  />
                </a>
                <a
                  href="https://www.linkedin.com"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img
                    src="/images/linkedin.png"
                    alt="LinkedIn"
                    height={20}
                    width={20}
                  />
                </a>
              </Box>
            </Box>
          </motion.div>
          <Box className={classes.column}>
            <Typography variant="h4" className={classes.heading}>
              {t("seekerfooter.forCandidates")}
            </Typography>
            <Link href="/job-search" className={classes.link}>
              {t("seekerfooter.browseJobs")}
            </Link>
            <Link href="/job-search" className={classes.link}>
              {t("seekerfooter.browseCategories")}
            </Link>
            <Link href="/contactus" className={classes.link}>
              {t("seekerfooter.contactUs")}
            </Link>
          </Box>

          <Box className={classes.column}>
            <Typography variant="h4" className={classes.heading}>
              {t("seekerfooter.aboutUs")}
            </Typography>
            <Link onClick={() => handleCardClick()} className={classes.link}>
              {t("seekerfooter.applicantProcess")}
            </Link>
            <Link href="/blog" className={classes.link}>
              {t("seekerfooter.blogs")}
            </Link>
          </Box>
          <Box className={classes.column}>
            <Typography variant="h4" className={classes.heading}>
              {t("seekerfooter.support")}
            </Typography>
            <Link href="/faq" className={classes.link}>
              {t("seekerfooter.faq")}
            </Link>
            <Link
              href="/privacy-policy"
              className={classes.link}
              target="_blank"
              rel="noopener noreferrer"
            >
              {t("seekerfooter.privacyPolicy")}
            </Link>

            <Link
              href="/terms&condition"
              target="_blank"
              rel="noopener noreferrer"
              className={classes.link}
            >
              {t("seekerfooter.termsConditions")}
            </Link>
          </Box>

          <Box className={classes.column5}>
            <Typography variant="h4" className={classes.heading}>
              {t("seekerfooter.downloadApp")}
            </Typography>
            <Typography variant="h6">
              {t("seekerfooter.downloadAppDescription")}
            </Typography>
            <motion.div
              initial="hidden"
              whileInView="visible"
              viewport={{ once: false, amount: 0.3 }}
            >
              <Box
                mt={2}
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  gap: "8px",
                }}
              >
                <a
                  href="https://www.apple.com/app-store/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img
                    src="/images/applestore.png"
                    alt="Apple Store"
                    height="40px"
                    style={{ objectFit: "contain" }}
                  />
                </a>

                <a
                  href="https://store.google.com/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img
                    src="/images/googlestore.png"
                    alt="Google Store"
                    height="40px"
                    style={{ objectFit: "contain" }}
                  />
                </a>
              </Box>
            </motion.div>
          </Box>
        </Box>

        <Divider className={classes.divider}></Divider>
        <Box mt={3} mb={2} className={classes.lowerbox}>
          <Box>
            <Typography variant="h6">{t("seekerfooter.copyright")}</Typography>
          </Box>
          <Box className={classes.lowerbox1}>
            <Link
              href="/privacy-policy"
              className={classes.link1}
              target="_blank"
              rel="noopener noreferrer"
            >
              {t("seekerfooter.privacyPolicyLink")}
            </Link>

            <Link
              href="/terms&condition"
              target="_blank"
              rel="noopener noreferrer"
              className={classes.link1}
            >
              {t("seekerfooter.termsConditionsLink")}
            </Link>
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default Footer;
