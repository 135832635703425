import {
  Box,
  Button,
  Checkbox,
  CircularProgress,
  Divider,
  FormControlLabel,
  FormHelperText,
  Grid,
  IconButton,
  InputAdornment,
  makeStyles,
  TextField,
  Modal,
  Typography,
} from "@material-ui/core";
import React, { useContext, useState } from "react";
import { useHistory } from "react-router-dom";
import { Formik, Form } from "formik";
import * as yup from "yup";
import { RecruiterAuthContext } from "../../../../context/recruiterAuth";
import EmailIcon from "@material-ui/icons/Email";
import LockOpenIcon from "@material-ui/icons/LockOpen";
import CloseIcon from "@material-ui/icons/Close";
import VisibilityIcon from "@material-ui/icons/Visibility";
import Popup from "../../../../component/modals/LoginModal";
import { postApiHandler, postDataHandler } from "../../../../config/service";
import toast from "react-hot-toast";
import { useTranslation } from "react-i18next";

import MailOutlineIcon from "@material-ui/icons/MailOutline";
import LockOutlinedIcon from "@material-ui/icons/LockOutlined";

const useStyles = makeStyles((theme) => ({
  authContainer: {
    //height: '540px',
    //marginTop: '12%',
    maxWidth: "500px",
    margin: "auto",
    display: "flex",
    flexDirection: "row",
    "@media(max-width:480px)": {
      //paddingTop: '40%',
      height: "510px",
      width: "350px",
    },
    "@media(max-width:390px)": {
      // paddingTop: '40%',
      height: "510px",
      width: "320px",
    },
    "@media(max-width:340px)": {
      ///paddingTop: '40%',
      height: "500px",
      width: "270px",
    },
  },
  formContainer: {
    borderRadius: "25px",
    backgroundColor: "white",
    padding: "48px",

    "@media(max-width:480px)": {
      borderRadius: "20px",
      padding: "20px",
    },
    position: "relative", // Add relative positioning
  },
  imageSection: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    "@media(max-width:430px)": {
      display: "none",
    },
  },
  image: {
    objectFit: "cover",
    borderRadius: "0px 20px 20px 0px",
  },
  staticContent: {
    top: 210,
    width: "16%",
    position: "absolute",
    color: "white",
    padding: theme.spacing(2),
    [theme.breakpoints.down("md")]: {
      top: 155,
      width: "20%",
      marginLeft: "-60px",
    },
  },
  heading: {
    fontWeight: 700,
    lineHeight: "29px",
    fontSize: 28,
    fontFamily: "Inter",
    color: "#262626",
    "@media(max-width:1200px)": {
      fontSize: 22,
      lineHeight: "28px",
    },
    "@media(max-width:992px)": {
      fontSize: 18,
      lineHeight: "24px",
    },
    "@media(max-width:767px)": {
      fontSize: 16,
      lineHeight: "18px",
    },
  },
  heading1: {
    color: "#000000CC",
    lineHeight: "23px",
  },
  remember: {
    marginTop: "10px",
    display: "flex",
    justifyContent: "space-between",
    "@media(max-width:1024px)": {
      gap: "52px",
    },
    "@media(max-width:768px)": {
      gap: "42px",
    },
    "@media(max-width:430px)": {
      gap: "32px",
    },
    "& .MuiFormControlLabel-root": {
      marginLeft: "-12px",
    },
  },
  staticheading1: {
    fontWeight: 400,
    fontSize: 20,
    lineHeight: "24px",
    color: "#FFFFFF",
    fontFamily: "Inter",
    marginBottom: theme.spacing(1),
  },
  staticheading2: {
    fontWeight: 700,
    fontSize: 20,
    lineHeight: "24px",
    color: "#FFFFFF",
    fontFamily: "Inter",
  },
  closeButton: {
    position: "absolute",
    top: theme.spacing(-3),
    right: theme.spacing(-3),
    height: "31px",
    width: "31px",
    color: theme.palette.grey[900],
    backgroundColor: "white",
    "@media(max-width:430px)": {
      top: "-19PX",
      right: "-28PX",
    },
    "&:hover": {
      color: theme.palette.grey[900],
      backgroundColor: "white",
    },
  },
  checkbox: {
    color: "#AFAFAF",
    "&.Mui-checked": {
      color: "#5E58E4",
    },
  },
  textFieldBox: {
    marginTop: "15px",
  },
}));

const RecruiterLogin = ({
  open,
  onClose,
  handleForgotClick,
  redirectPath = "/recruiterDashboard",
  setRedirectPath,
}) => {
  const { recruiterLogIn } = useContext(RecruiterAuthContext);
  const language = sessionStorage.getItem("language") || "en";
  const classes = useStyles();
  const history = useHistory();
  const [showPassword, setShowPassword] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isRememberMe, setIsRememberMe] = useState(false);
  const { t } = useTranslation();
  const handleClick = () => {
    onClose();
    history.push("/Companyregistration");
  };

  const handleForgotOpen = () => {
    onClose();
    handleForgotClick();
  };
  const formInitialSchema = {
    email: "",
    password: "",
  };

  const formValidationSchema = yup.object().shape({
    email: yup
      .string()
      .matches(
        /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
        t("recruiterLogin.validation.email.invalid")
      )
      .max(320, t("recruiterLogin.validation.email.maxLength"))
      .required(t("recruiterLogin.validation.email.required")),

    password: yup
      .string()
      .required(t("recruiterLogin.validation.password.required")),
  });

  const validateEmail = async (email) => {
    const isValid = formValidationSchema.isValidSync({ email });
    return isValid;
  };

  const handleFormSubmit = async (values) => {
    const data = {
      email: values.email,
      password: values.password,
      preferredLanguage: language
    };

    setIsLoading(true);

    try {
      const response = await postDataHandler("recruiterLogin", data);
      if (response.responseCode === 200) {
        toast.success(response?.data?.responseMessage);
        const { token, id, email, subscriptionStatus,fullName } = response.result;
        recruiterLogIn(token, subscriptionStatus,id,email,fullName);

        localStorage.setItem("id", id);

        localStorage.setItem("email", email);
        if (isRememberMe) {
          // Store credentials securely if "Remember Me" is checked
          localStorage.setItem("email", values.email);
        }

        onClose();

        if (subscriptionStatus === "ACTIVE") {
          // Redirect to the original target or home page if no target is set
          history.push(redirectPath);
        } else {
          // Redirect to pricing page if subscription is inactive
          history.push("/pricings");
        }

        setRedirectPath("");

        setRedirectPath("");
      } else {
        console.log(response?.data?.responseMessage);
        toast.error(response?.data?.responseMessage);
      }
    } catch (error) {
      toast.error("Login failed. Please try again.");
    } finally {
      setIsLoading(false); // Stop loading spinner
    }
  };

  return (
    <>
      <Popup open={open} handleClose={onClose}>
        <Box className={classes.authContainer}>
          <Box className={classes.formContainer}>
            <Box>
              <Typography
                variant="body6"
                align="left"
                className={classes.heading}
              >
                {t("loginNow")}
              </Typography>
              <Box my={1.4}>
                <Typography variant="h5" className={classes.heading1}>
                  {t("loginDescription")}
                </Typography>
              </Box>

              <Formik
                initialValues={formInitialSchema}
                validationSchema={formValidationSchema}
                onSubmit={(values) => handleFormSubmit(values)}
              >
                {({ errors, handleBlur, handleChange, touched, values }) => (
                  <Form>
                    <Box className={classes.textFieldBox}>
                      <TextField
                        fullWidth
                        variant="outlined"
                        placeholder={t("emailPlaceholder")}
                        name="email"
                        id="email"
                        inputProps={{ maxLength: 320 }}
                        value={values.email}
                        error={Boolean(touched.email && errors.email)}
                        onBlur={handleBlur}
                        onChange={handleChange}
                        onInput={(e) => {
                          let value = e.target.value;
                          value = value.replace(/\s/g, "").toLowerCase();
                          e.target.value = value;
                          window.localStorage.setItem("email", e.target.value);
                        }}
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">
                              <Box display="flex" alignItems="center">
                                <img
                                  src="images/email.svg"
                                  height={"23px"}
                                  width={"23px"}
                                  style={{ color: "#00000080" }}
                                />
                                <Divider
                                  orientation="vertical"
                                  flexItem
                                  style={{ height: "34px", margin: "0 15px" }}
                                />
                              </Box>
                            </InputAdornment>
                          ),
                        }}
                      />
                      <FormHelperText error>
                        {touched.email && errors.email}
                      </FormHelperText>
                    </Box>

                    <Box className={classes.textFieldBox}>
                      <TextField
                        fullWidth
                        variant="outlined"
                        placeholder={t("passwordPlaceholder")}
                        type={showPassword ? "text" : "password"}
                        name="password"
                        id="password"
                        inputProps={{ maxLength: 20 }}
                        onCopy={(event) => event.preventDefault()}
                        onCut={(event) => event.preventDefault()}
                        onPaste={(event) => event.preventDefault()}
                        value={values.password}
                        error={Boolean(touched.password && errors.password)}
                        onBlur={handleBlur}
                        onChange={handleChange}
                        onInput={(e) => {
                          const regex = /^[^\s]*$/;
                          if (!regex.test(e.target.value)) {
                            e.target.value =
                              e.target.dataset.previousValue || "";
                          } else {
                            e.target.dataset.previousValue = e.target.value;
                          }
                          window.localStorage.setItem(
                            "password",
                            e.target.value
                          );
                        }}
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">
                              <Box display="flex" alignItems="center">
                                <img
                                  src="/images/lock1.svg"
                                  height={"25px"}
                                  width={"25px"}
                                  style={{ color: "#00000080" }}
                                />
                                <Divider
                                  orientation="vertical"
                                  flexItem
                                  style={{ height: "34px", margin: "0 15px" }}
                                />
                              </Box>
                            </InputAdornment>
                          ),
                          endAdornment: (
                            <InputAdornment position="end">
                              <IconButton
                                onClick={() => setShowPassword(!showPassword)}
                                edge="end"
                              >
                                <Box
                                  display="flex"
                                  alignItems="center"
                                  justifyContent="center"
                                >
                                  {showPassword ? (
                                    <VisibilityIcon
                                      className={classes.iconClass1}
                                      style={{
                                        width: "18px",
                                        height: "18px",
                                        display: "flex",
                                        alignItems: "center",
                                      }}
                                    />
                                  ) : (
                                    <img
                                      src="/images/eyeP.svg"
                                      style={{
                                        width: "18px",
                                        height: "18px",
                                        objectFit: "contain",
                                      }}
                                    />
                                  )}
                                </Box>
                              </IconButton>
                            </InputAdornment>
                          ),
                        }}
                      />
                      <FormHelperText error>
                        {touched.password && errors.password}
                      </FormHelperText>
                    </Box>

                    <Grid
                      container
                      alignItems="center"
                      className={classes.remember}
                    >
                      <Grid item>
                        <FormControlLabel
                          control={
                            <Checkbox
                              className={classes.checkbox}
                              checked={isRememberMe}
                              onChange={(e) =>
                                setIsRememberMe(e.target.checked)
                              }
                              name="rememberMe"
                              color="primary"
                            />
                          }
                          label={
                            <Typography
                              variant="h5"
                              style={{
                                color: "#666666",
                                lineHeight: "16px",
                                marginLeft: "0px",
                                textWrap: "nowrap",
                              }}
                            >
                              {t("rememberMe")}
                            </Typography>
                          }
                        />
                      </Grid>
                      <Grid item>
                        <Typography
                          variant="h5"
                          style={{
                            fontSize: "16px",
                            color: "#5E58E4",
                            textDecoration: "",
                            cursor: "pointer",
                          }}
                          onClick={handleForgotOpen}
                        >
                          {t("recforgotPassword")}
                        </Typography>
                      </Grid>
                    </Grid>

                    <Box>
                      <Button
                        style={{
                          marginTop: "15px",
                          borderRadius: "15px",
                          backgroundColor: "#5E58E4",
                          color: "white",
                          fontWeight: "700",
                          textTransform: "none",
                        }}
                        variant="contained"
                        color="secondary"
                        fullWidth
                        type="submit"
                        disabled={isLoading}
                      >
                        {t("loginBtn")}
                        {isLoading && (
                          <CircularProgress
                            size={20}
                            style={{ color: "#fff", marginLeft: "10px" }}
                          />
                        )}
                      </Button>
                    </Box>

                    <Box
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        gap: "15px",
                        justifyContent: "center",
                        alignItems: "center",
                        marginTop: "25px",
                      }}
                    >
                      <Typography variant="h5" style={{ fontSize: "16px" }}>
                        {t("newUser")}
                        <a
                          style={{
                            color: "#5E58E4",
                            cursor: "pointer",
                            marginLeft: "8px",
                          }}
                          onClick={handleClick}
                        >
                          {t("signUp")}
                        </a>
                      </Typography>
                    </Box>
                  </Form>
                )}
              </Formik>
            </Box>
          </Box>
        </Box>
      </Popup>
    </>
  );
};

export default RecruiterLogin;
