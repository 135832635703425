// const url = "http://172.16.2.17:2155/api/v1"; //Rajnikant
// const url = "http://localhost:2155/api/v1"; //ansh
// const url = "http://172.16.1.114:2155/api-docs/"
// const url = "http://172.16.6.41:2155/api/v1"; //Anurag
const url = "https://node-jobguru.mobiloitte.io/api/v1";
// const url = "http://172.16.6.42:2155/api/v1"; //Aamir
// const url = 'http://47.130.67.132:2121/api/v1"; //Aamir
// const url = "http://172.16.1.209:2155/api/v1"; //Arish
// const url = "http://172.16.1.128:2155/api/v1"; //harsh
// const url = "http://172.16.6.83:2155/api/v1"; //izhar

const ApiConfig = {
  recruiterLogin: `${url}/recruiter/login`,
  resendOTP: `${url}/recruiter/resendOTP`,
  verifyOTP: `${url}/recruiter/verifyOTP`,
  resetPassword: `${url}/recruiter/resetPassword`,
  changePassword: `${url}/recruiter/changePassword`,
  deleteRecruiter: `${url}/recruiter/deleteRecruiter`,
  listRecruitersJobApplications: `${url}/recruiter/listRecruitersJobApplications`,

  recruiterSignup: `${url}/recruiter/registerCompany`,
  editProfile: `${url}/recruiter/editProfile`,
  getRProfile: `${url}/recruiter/getProfile`,
  //seeker
  seekerLogin: `${url}/user/userLogin`, 
  socialLogin: `${url}/user/socialLogin`,
  signUp: `${url}/user/userSignUp`,
  verifyOtpSeeker: `${url}/user/verifyOTP`,
  resendOtpSeeker: `${url}/user/resendOTP`,
  resetPasswordSeeker: `${url}/user/resetPassword`,
  listBlogs: `${url}/user/listBlogs`,
  viewBlog: `${url}/user/viewBlog`,
  getJobCategory: `${url}/user/getJobCategory`,
  listJobLocations: `${url}/user/listJobLocations`,
  uploadResume: `${url}/user/uploadResume`,
  uploadResume2: `${url}/user/uploadResume2`,
  updateProfile: `${url}/user/updateProfile`,
  applyJob: `${url}/user/applyJob`,
  getUserAppliedJobs: `${url}/user/getUserAppliedJobs`,
  getIndustriesSector: `${url}/user/getIndustriesSector`,
  getIndustries: `${url}/user/getIndustries`,
  getDasboardStats: `${url}/user/getDasboardStats`,
  getRecommendedJobs: `${url}/user/getRecommendedJobs`,
  getOneJob: `${url}/user/getOneJob`,
  subscribe: `${url}/user/subscribe`,
  searchskills: `${url}/user/searchskills`,
  getTopJobs: `${url}/user/getTopJobs`,

  //job
  getLatestJobs: `${url}/jobs/latestJobs`,
  filterjobs: `${url}/jobs/jobSearch`,
  getJobsIndustry: `${url}/jobs/JobsIndustry`,

  //upload file

  uploadFile: `${url}/common/uploadFile`,
  //job
  getLatestJobs: `${url}/jobs/latestJobs`,
  getJobsIndustry: `${url}/jobs/JobsIndustry`,
  getProfile: `${url}/user/getProfile`,

  // saveorUnsave Api
  saveUnsaveJob: `${url}/user/saveUnsaveJob`,
  getSavedJobs: `${url}/user/getSavedJobs`,
  //recruiter
  uploadJd: `${url}/recruiter/uploadJd`,
  generateJd: `${url}/recruiter/generateJd`,
  addUpdateJob: `${url}/recruiter/addUpdateJobPosting`,
  listJobs: `${url}/recruiter/listJobs`,
  viewJob: `${url}/recruiter/viewJob`,
  deleteJob: `${url}/recruiter/deleteJob`,
  listBrands: `${url}/recruiter/listBrands`,

  //notification
  getAllNotifications: `${url}/notification/getAllNotifications`,
  updateNotificationStatus: `${url}/notification/updateNotificationStatus`,
  markAllCompleted: `${url}/notification/markAllCompleted`,
  deleteAllNotifications: `${url}/notification/deleteAll`,

  //Subscriptions
  listSubscriptions: `${url}/recruiter/listSubscriptions`,
  buySubscription: `${url}/subscription/buySubscription`,
  captureAndVerifySubscription: `${url}/subscription/captureAndVerifySubscription`,
  getLatestActiveSubscription: `${url}/subscription/getLatestActiveSubscription`,

  //testimonials

  listTestimonials: `${url}/recruiter/listTestimonials`,
  viewSubscriptions: `${url}/recruiter/viewSubscriptions`,
  dashboardData: `${url}/recruiter/getDashboardData`,
  getApplicant: `${url}/recruiter/getApplicant`,

  //Top Recruiters
  getTopHiringCompanies: `${url}/user/getTopHiringCompanies`,
  updateJobApplication: `${url}/recruiter/updateJobApplication`,

  listJobCategoriesWithJobs: `${url}/user/listJobCategoriesWithJobs`,
  updateJobApplication: `${url}/recruiter/updateJobApplication`,
  getUserProfileViews: `${url}/user/getUserProfileViews`,

  //Contact us
  contactUs: `${url}/user/contactUs`,

  //listRecruiterPayments
  listTransactions: `${url}/recruiter/listTransactions`,
  //analytics report
  getJobApplicationsData: `${url}/user/getJobApplicationsData`,
  getUserProfileViews: `${url}/user/getUserProfileViews`,
  //recruiter dashboard
  getCandidateHired: `${url}/recruiter/getCandidateHired`,
  findRecentApplicantsForRecruiter: `${url}/recruiter/findRecentApplicantsForRecruiter`,
  listSkills: `${url}/recruiter/listSkills`,
};
export default ApiConfig;
