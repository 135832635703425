import React, { createContext, useContext, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import {
  getApiHandlerWithToken,
  postApiHandlerWithToken,
} from "../config/service";
import toast from "react-hot-toast";
import { useTranslation } from "react-i18next";

export const ProfileContext = createContext();

export default function ProfileProvider(props) {
  const [profileData, setProfileData] = useState(null);
  const [profile, setProfile] = useState(null);
  const [loading, setLoading] = useState(true);
  const navigate = useHistory();
  const [activeStep, setActiveStep] = useState(0);
  const [completeProfile, setCompleteProfile] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [location, setLocation] = useState("");
  const [notifications, setNotifications] = useState([]);
  const [formData, setFormData] = useState(null);
  const [avatarSrc, setAvatarSrc] = useState(null);
  const userData = sessionStorage.getItem("user");
  const userObject = userData ? JSON.parse(userData) : null;
  const userId = userObject?.id;
  const email = userObject?.email;
  const [uploadedAt, setUploadedAt] = useState(null);
  const {t} = useTranslation();
  const steps = [
    t("seekerProfileCompletion.personalInformation"),
    t("seekerProfileCompletion.educationalBackground"),
    t("seekerProfileCompletion.professionalExperience"),
    t("seekerProfileCompletion.skills"),
    t("seekerProfileCompletion.languages"),
  ];

  const sectionRefs = {
    resume: React.createRef(),
    summary: React.createRef(),
    personalInfo: React.createRef(),
    education: React.createRef(),
    professional: React.createRef(),
    skills: React.createRef(),
    awards: React.createRef(),
    languages: React.createRef(),
  };
  const scrollToSection = (sectionName) => {
    sectionRefs[sectionName]?.current?.scrollIntoView({
      behavior: "smooth",
      // block: "start",
    });
  };


  const apiDataHandler = async (
    section = "section",
    values,
    n,
    complete,
    isDetail,
    path
  ) => {
    console.log(values);
    const data = {
      ...values,
      isActive: n,
      isComplete: complete,
      isDetail: isDetail,
      email: email,
    };
    setIsLoading(true);
    try {
      const response = await postApiHandlerWithToken("updateProfile", data);
      if (response.responseCode == 200) {
        setActiveStep(n);
        // toast.success(`${section} saved successfully`);

        if (path) {
          console.log('pathhpathpath')
          navigate.push(path);
        }
      } else {
        toast.error(response.responseMessage);
      }
    } catch (error) {
      console.log("Error in data post", error);
    } finally {
      setIsLoading(false);
    }
  };

  const getProfile = async () => {
    try {
      const userData = sessionStorage.getItem("user");
      const userObject = userData ? JSON.parse(userData) : null;
      const userId = userObject?.id;
      const res = await getApiHandlerWithToken("getProfile", { userId });
      if (res?.responseCode === 200) {
        setProfile(res?.result);
        setCompleteProfile(res?.result?.isComplete);
      } else {
        console.log("Something went wrong");
      }
    } catch (error) {
      console.error("Error while getting profile", error);
    } finally {
      setLoading(false);
    }
  };

   useEffect(() => {
    scrollToSection("resume");
  }, []);

  const contextData = {
    profileData,
    setProfileData,
    activeStep,
    setActiveStep,
    completeProfile,
    setCompleteProfile,
    apiDataHandler,
    formData,
    setFormData,
    location,
    setLocation,
    notifications,
    setNotifications,
    uploadedAt,
    setUploadedAt,
    profile,
    setProfile,
    loading,
    getProfile,
    avatarSrc,
    setAvatarSrc,
    steps,
    sectionRefs, // Section references
    scrollToSection, // Scroll to specific section
  };

  return (
    <ProfileContext.Provider value={contextData}>
      {props.children}
    </ProfileContext.Provider>
  );
}
