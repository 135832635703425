import {
  Box,
  Button,
  Checkbox,
  CircularProgress,
  Divider,
  FormControlLabel,
  FormHelperText,
  Grid,
  IconButton,
  InputAdornment,
  makeStyles,
  TextField,
  Modal,
  Typography,
} from "@material-ui/core";
import React, { useContext, useState } from "react";
import { useHistory } from "react-router-dom";
import { AuthContext } from "../../../../context/Auth";
import CloseIcon from "@material-ui/icons/Close";
import Popup from "../../../../component/modals/LoginModal";

const useStyles = makeStyles((theme) => ({
  authContainer: {
    //marginTop: '90px',
    //maxWidth: '870px',
    margin: "auto",
    display: "flex",
    flexDirection: "row",

    "@media(max-width:1300px)": {},
    "@media(max-width:1024px)": {
      // paddingTop: "10%",
      //width: '600px',
    },
    "@media(max-width:768px)": {
      // paddingTop: '8%',
      //width: '450px',
      //flexDirection: 'column',
    },
    "@media(max-width:430px)": {
      //paddingTop: '15%',
      // width: '320px',
    },
  },
  formContainer: {
    borderRadius: "20px 0px 0px 20px",
    backgroundColor: "white",
    padding: theme.spacing(4),
    "@media(max-width:768px)": {
      padding: theme.spacing(4),
      borderRadius: "20px",
    },
    "@media(max-width:1030px)": {
      padding: theme.spacing(4),
      borderRadius: "20px",
    },

    "@media(max-width:430px)": {
      borderRadius: "20px",
      padding: theme.spacing(2),
    },
    position: "relative", // Add relative positioning
  },
  imageSection: {
    display: "flex",
    justifyContent: "center",
    "@media(max-width:1030px)": {
      display: "none",
      borderRadius: "20px",
    },
    "@media(max-width:430px)": {
      display: "none",
      borderRadius: "20px",
    },
  },
  image: {
    objectFit: "contain",
    borderRadius: "0px 20px 20px 0px",

    "@media(max-width:768px)": {
      width: "100%",
      objectFit: "contain",
      height: "100%",
      borderRadius: "0px 20px 20px 0px",
    },
  },
  staticContent: {
    top: "129px",
    width: "21%",
    position: "absolute",
    color: "white",
    padding: theme.spacing(0),
    [theme.breakpoints.down("md")]: {
      top: "132px",
      width: "21%",
      marginLeft: "-60px",
    },
  },
  heading: {
    fontWeight: 700,
    lineHeight: "29px",
    fontSize: 24,
    fontFamily: "Inter",
    color: "#262626",
    "@media(max-width:1200px)": {
      fontSize: 22,
      lineHeight: "28px",
    },
    "@media(max-width:992px)": {
      fontSize: 18,
      lineHeight: "24px",
    },
    "@media(max-width:767px)": {
      fontSize: 16,
      lineHeight: "18px",
    },
  },
  // heading1: {
  //     lineHeight: '20px',
  // },
  msg: {
    marginTop: "10px",
    lineHeight: "20px",
  },

  staticheading1: {
    fontWeight: 400,
    fontSize: 20,
    lineHeight: "24px",
    color: "#FFFFFF",
    fontFamily: "Inter",
    marginBottom: theme.spacing(1),
  },
  staticheading2: {
    fontWeight: 700,
    fontSize: 20,
    lineHeight: "24px",
    color: "#FFFFFF",
    fontFamily: "Inter",
  },
  headingBox: {
    marginTop: "10px",

    gap: "15px",
  },
  closeButton: {
    position: "absolute",
    top: theme.spacing(-3),
    right: theme.spacing(-57),
    height: "31px",
    width: "31px",
    color: theme.palette.grey[900],
    backgroundColor: "white",
    "@media(max-width:1030px)": {
      top: "-19PX",
      right: "-28PX",
    },
    "&:hover": {
      color: theme.palette.grey[900],
      backgroundColor: "white",
    },
  },
  btnbox: {
    marginTop: "9px",
    "@media(max-width:430px)": {
      marginTop: "20px",
    },
  },
  buttns: {
    marginTop: "15px",
    color: "#353434",
    backgroundColor: "rgba(223, 223, 223, 1)",
    "&:hover": {
      border: "1px solid #DFDFDF",
      boxShadow: "none",
      color: "#353434",
      backgroundColor: "#fff",
    },
  },
}));

const CompleteProfile = ({ open, onClose }) => {
  const auth = useContext(AuthContext);
  const classes = useStyles();
  const history = useHistory();
  const [showPassword, setShowPassword] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const handleUploadCV = async (values) => {
    history.push("/profile");
  };

  const handleCreateCV = async (values) => {
    history.push("/completeprofilewitoutCV");
  };
  return (
    <>
      <Popup open={open} handleClose={onClose}>
        <Box className={classes.authContainer}>
          <Box className={classes.formContainer}>
            {/* <IconButton
                            className={classes.closeButton}
                            onClick={onClose}
                        >
                            <CloseIcon />
                        </IconButton> */}
            <Box style={{ padding: "20px", textAlign: "center" }}>
              <Box className={classes.headingBox}>
                <Typography className={classes.heading}>
                  Account Created
                </Typography>
                <Typography variant="h6" className={classes.msg}>
                  Your account has been created successfully!
                </Typography>
              </Box>
              <Box mt={5}>
                <img src="images/success.png"></img>
              </Box>
              <Box mt={5}>
                {" "}
                <Typography className={classes.heading}>
                  Complete Your Profile
                </Typography>
              </Box>
              <Box className={classes.btnbox}>
                <Button
                  className={classes.buttns}
                  variant="contained"
                  color="primary"
                  fullWidth
                  type="submit"
                  onClick={handleUploadCV}
                >
                  Upload your CV/Resume
                </Button>
            
              </Box>
            </Box>
          </Box>
          <Box className={classes.imageSection}>
            <img
              src="images/account-create.png"
              alt="Illustration"
              className={classes.image}
            />
          </Box>
        </Box>
      </Popup>
    </>
  );
};

export default CompleteProfile;
