import React, { useContext } from "react";
import { Route, useHistory } from "react-router-dom";
import { AuthContext } from "../context/Auth";
import { RecruiterAuthContext } from "../context/recruiterAuth";


const AuthGuard = ({ component: Component, ...rest }) => {
  const { isLogin } = useContext(AuthContext);
  const { recruiterLoggedIn } = useContext(RecruiterAuthContext);

  const history = useHistory();
     
  if (!isLogin ) {
    history.push("/"); 
    return null;
  }



if (recruiterLoggedIn) {
  history.push("/recruiterDashboard"); 
  return null; 
}


  return <Route {...rest} render={(props) => <Component {...props} />} />;
};

export default AuthGuard;
