import React from "react";
import {
  Dialog,
  DialogContent,
  DialogActions,
  Box,
  Typography,
  Button,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { useHistory } from "react-router-dom";
import { postDataHandlerWithToken } from "../../config/service";
import { useTranslation } from "react-i18next";
// Define the useStyles hook
const useStyles = makeStyles((theme) => ({
  dialogBox: {
    padding: "20px 14%",
  },
  dialogContent: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    textAlign: "center",
    width: "100%",
  },
  modalImage: {
    width: "35%",
    height: "auto",
    objectFit: "contain",
    marginBottom: "16px",
  },
  dialogActions: {
    justifyContent: "center",
  },
  buttonNoHover: {
    backgroundColor: "rgba(91, 90, 91, 1)",
    border: "none",
  },
  bottomBox: {
    position: "absolute",
    bottom: -16,
    left: "50%",
    transform: "translateX(-50%)",
    height: "16px",
    backgroundColor: "#5E58E4",
    width: "80%",
    borderBottomLeftRadius: "16px",
    borderBottomRightRadius: "16px",
  },
}));

const DeleteProfile = ({
  open,
  onClose,
  Img,
  message,
  buttonText1,
  buttonText2,
}) => {
  const classes = useStyles();
  const history = useHistory();
  const { t } = useTranslation();
  const handleDelete = async () => {
    try {
      const res = await postDataHandlerWithToken("deleteRecruiter");
      if (res.responseCode === 200) {
        history.push("/recruiterlandingpage");
      }
    } catch (error) {
      console.log(error);
    }
  };
  return (
    <>
      <Dialog open={open} onClose={onClose} maxWidth="sm">
        <Box className={classes.dialogBox}>
          <DialogContent className={classes.dialogContent}>
            <img src={Img} alt="Modal" className={classes.modalImage} />
            <Typography>{message}</Typography>
          </DialogContent>
          <DialogActions className={classes.dialogActions}>
            <Button
              variant="contained"
              color="secondary"
              onClick={onClose}
              classes={{ root: classes.buttonNoHover }}
              fullWidth
            >
              {buttonText1}
            </Button>
            <Button
              variant="contained"
              color="secondary"
              onClick={handleDelete}
              fullWidth
            >
              {buttonText2}
            </Button>
          </DialogActions>
        </Box>
        <Box className={classes.bottomBox} />
      </Dialog>
    </>
  );
};

export default DeleteProfile;
