import {
  Box,
  Button,
  Checkbox,
  CircularProgress,
  Divider,
  FormControlLabel,
  FormHelperText,
  Grid,
  IconButton,
  InputAdornment,
  makeStyles,
  TextField,
  Modal,
  Typography,
} from "@material-ui/core";
import React, { useContext, useState } from "react";
import { useHistory } from "react-router-dom";
import { Formik, Form } from "formik";
import * as yup from "yup";
import { AuthContext } from "../../../../context/Auth";
import Login from "./Login";
import OTPFlow from "./OTPFlow";
import Popup from "../../../../component/modals/LoginModal";
import VisibilityIcon from "@material-ui/icons/Visibility";
import { postApiHandler } from "../../../../config/service";
import toast from "react-hot-toast";
import OAuth from "../../../../component/OAuth";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles((theme) => ({
  authContainer: {
    display: "flex",
    flexDirection: "row",
    "& .MuiOutlinedInput-notchedOutline": {
      borderColor: "#AFB0B6",
    },

    "@media(max-width:1300px)": {},
  },
  formContainer: {
    borderRadius: "20px 0px 0px 20px",
    backgroundColor: "white",
    padding: theme.spacing(5),
    "@media(max-width:768px)": {
      padding: theme.spacing(4),
      borderRadius: "20px",
    },
    "@media(max-width:1030px)": {
      padding: theme.spacing(4),
      borderRadius: "20px",
    },

    "@media(max-width:430px)": {
      borderRadius: "20px",
      padding: theme.spacing(2),
    },
    position: "relative",
  },
  imageSection: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    "@media(max-width:1030px)": {
      display: "none",
      borderRadius: "20px",
    },
    "@media(max-width:430px)": {
      display: "none",
      borderRadius: "20px",
    },
  },
  image: {
    objectFit: "contain",
    borderRadius: "0px 20px 20px 0px",
    height: "100%",

    "@media(max-width:768px)": {
      width: "100%",
      objectFit: "contain",
      height: "100%",
      borderRadius: "0px 20px 20px 0px",
    },
  },
  heading: {
    fontWeight: 700,
    lineHeight: "29px",
    fontSize: 24,
    fontFamily: "Inter",
    color: "#262626",
    "@media(max-width:1200px)": {
      fontSize: 22,
      lineHeight: "28px",
    },
    "@media(max-width:992px)": {
      fontSize: 18,
      lineHeight: "24px",
    },
    "@media(max-width:767px)": {
      fontSize: 16,
      lineHeight: "18px",
    },
  },
  termscondition: {
    "@media(max-width:768px)": {
      fontSize: "10px",
      lineHeight: "18px",
    },
    "@media(max-width:1030px)": {
      fontSize: "10px",
      "@media(max-width:1430px)": {
        fontSize: "10px",
        lineHeight: "18px",
      },
    },
  },
  heading1: {
    marginTop: "10px",
    color: "rgba(0, 0, 0, 0.8)",
    lineHeight: "22px",
    "@media(max-width:1430px)": {
      fontSize: "13px",
    },
    "@media(max-width:768px)": {
      fontSize: "10px",
    },
    "@media(max-width:1030px)": {
      fontSize: "12px",
    },
  },
  remember: {
    marginTop: "10px",
    display: "flex",
    justifyContent: "space-between",
    "@media(max-width:1024px)": {
      gap: "52px",
    },
    "@media(max-width:768px)": {
      gap: "42px",
    },
    "@media(max-width:430px)": {
      gap: "32px",
    },
    "& .MuiFormControlLabel-root": {
      marginLeft: "0px",
    },
  },

  check: {
    "& .MuiFormControlLabel-root": {
      marginLeft: "1% !important",
    },
    "& .PrivateSwitchBase-root": {
      paddingLeft: "0px",
    },
  },

  checkbox: {
    marginLeft: "2px",
    color: "#AFAFAF",
    "&.Mui-checked": {
      color: "#5E58E4",
    },
  },
  btn: {
    marginTop: "18px",
    backgroundColor: "#5E58E4",
    color: "white",
    textTransform: "none",
  },
  textFieldBox: {
    marginTop: "18px",
    "& .MuiOutlinedInput-root": {
      marginTop: "9px !important", // Using !important to override conflicting styles
    },
  },
  staticContent: {
    top: "85px",
    width: "29%",
    position: "absolute",
    color: "white",
    padding: theme.spacing(0),
  },
  staticheading1: {
    fontWeight: 400,
    fontSize: 20,
    lineHeight: "24px",
    color: "#FFFFFF",
    fontFamily: "Inter",
    marginBottom: theme.spacing(1),
  },
  staticheading2: {
    fontWeight: 700,
    fontSize: 20,
    lineHeight: "24px",
    color: "#FFFFFF",
    fontFamily: "Inter",
  },
  errorText: {
    color: "#FE3737",
    "@media(min-width:1030px)": {
      "&.MuiFormHelperText-root.Mui-error": {
        position: "absolute"
      },
    },

  },
  termsConditionWrapper: {
    marginTop: "18px",
    alignItems: "center",
    "@media(min-width:1030px)": {
      alignItems: "center",
      marginTop: "25px",
    },
  },
  createbtn: {
    marginTop: "21px",
    backgroundColor: "#5E58E4",
    color: "white",
    textTransform: "none",
    "@media(min-width:1030px)": {
      marginTop: "23px",
    },
  }
}));

const SignUp = ({
  open,
  onClose,
  handleLoginClick,
  handletestClick,
  setEmail,
}) => {
  const auth = useContext(AuthContext);
  const classes = useStyles();
  const history = useHistory();
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isRememberMe, setIsRememberMe] = useState(false);
  const [openLogin, setOpenLogin] = React.useState(false);
  const [openOTP, setOpenOTP] = React.useState(false);
  const { t } = useTranslation();
  const language = sessionStorage.getItem("language") || "en";
  const handleLoginOpen = () => {
    setOpenLogin(false);
    onClose();
    handleLoginClick();
  };
  const handleLoginClose = () => setOpenLogin(false);

  const handleOTPOpen = () => {
    onClose();
    console.log("handle otp clicked", handletestClick);

    handletestClick();
  };
  const handleOTPClose = () => setOpenOTP(false);

  const handleChecked = (newCheckedValue) => {
    console.log("Checkbox new value:", newCheckedValue);
  };

  const formInitialSchema = {
    email: "",
    password: "",
    // cpassword: "",
    termsandcondition: false,
  };

  const formValidationSchema = yup.object().shape({
    email: yup
      .string()
      .matches(
        /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
        useTranslation().t("signUpValidation.email.invalid")
      )
      .max(256, useTranslation().t("signUpValidation.email.maxLength"))
      .required(useTranslation().t("signUpValidation.email.required")),
    password: yup
      .string()
      .required(useTranslation().t("signUpValidation.password.required"))
      .min(8, useTranslation().t("signUpValidation.password.minLength")),
    // cpassword: yup
    //   .string()
    //   .oneOf(
    //     [yup.ref("password"), null],
    //     useTranslation().t("signUpValidation.confirmPassword.mismatch")
    //   )
    //   .required(
    //     useTranslation().t("signUpValidation.confirmPassword.required")
    //   ),
    termsandcondition: yup
      .boolean()
      .oneOf(
        [true],
        useTranslation().t("signUpValidation.termsAndConditions.required")
      ),
  });
  const validateEmail = async (email) => {
    const isValid = formValidationSchema.isValidSync({ email });
    return isValid;
  };

  const handleFormSubmit = async (values) => {
    const data = {
      email: values.email.toLowerCase(),
      password: values.password,
      preferredLanguage: language || "en",
    };
    setIsLoading(true);
    try {
      const response = await postApiHandler("signUp", data);
      if (response?.responseCode === 200) {
        toast.success(
          `OTP sent successfully to ${data?.email || "your email"}`
        );
        setEmail(values.email);
        onClose();
        handleOTPOpen();
      } else {
        console.log(response.responseMessage);
        toast.error(response.responseMessage);
      }
    } catch (error) {
      setIsLoading(false);
      console.log(error?.responseMessage);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <>
      <Popup open={open} handleClose={onClose}>
        <Box className={classes.authContainer}>
          <Box className={classes.formContainer}>
            <Box style={{}}>
              <Typography
                variant="body5"
                align="left"
                className={classes.heading}
              >
                {t("seekerSignIn.heading")}
              </Typography>
              <Box my={1}>
                <Typography variant="h6" className={classes.heading1}>
                  {t("seekerSignIn.subheading")}
                </Typography>
              </Box>

              <Formik
                initialValues={formInitialSchema}
                validationSchema={formValidationSchema}
                onSubmit={(values) => handleFormSubmit(values)}
              >
                {({
                  errors,
                  handleBlur,
                  handleChange,
                  handleSubmit,
                  setFieldValue,
                  touched,
                  values,
                }) => (
                  <Form>
                    <Box className={classes.textFieldBox}>
                      <TextField
                        fullWidth
                        variant="outlined"
                        placeholder={t("seekerSignIn.emailPlaceholder")}
                        name="email"
                        id="email"
                        inputProps={{
                          maxLength: 62,
                        }}
                        value={values.email}
                        error={Boolean(touched.email && errors.email)}
                        onBlur={handleBlur}
                        onChange={handleChange}
                        onInput={(e) => {
                          let value = e.target.value;
                          value = value.replace(/\s/g, "").toLowerCase();
                          e.target.value = value;
                        }}
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">
                              <Box display="flex" alignItems="center">
                                <img
                                  src="/images/email.svg"
                                  style={{ color: "#252525" }}
                                />
                                <Divider
                                  orientation="vertical"
                                  flexItem
                                  style={{
                                    height: "34px",
                                    margin: "0 10px 0px 16px",
                                  }}
                                />
                              </Box>
                            </InputAdornment>
                          ),
                        }}
                      />

                      <FormHelperText error className={classes.errorText}>
                        {touched.email && errors.email}
                      </FormHelperText>
                    </Box>
                    <Box className={classes.textFieldBox}>
                      <TextField
                        fullWidth
                        variant="outlined"
                        placeholder={t("seekerSignIn.passwordPlaceholder")}
                        type={showPassword ? "text" : "password"}
                        name="password"
                        id="password"
                        inputProps={{
                          maxLength: 62,
                          onCopy: (e) => e.preventDefault(), // Prevent copy
                          onPaste: (e) => e.preventDefault(), // Prevent paste
                        }}
                        value={values.password}
                        error={Boolean(touched.password && errors.password)}
                        onBlur={handleBlur}
                        onChange={handleChange}
                        onInput={(e) => {
                          const regex = /^[^\s]*$/;
                          if (!regex.test(e.target.value)) {
                            e.target.value =
                              e.target.dataset.previousValue || "";
                          } else {
                            e.target.dataset.previousValue = e.target.value;
                          }
                        }}
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">
                              <Box display="flex" alignItems="center">
                                <img
                                  src="/images/lock1.svg"
                                  style={{ color: "#00000080" }}
                                />
                                <Divider
                                  orientation="vertical"
                                  flexItem
                                  style={{ height: "34px", margin: "0 10px" }}
                                />
                              </Box>
                            </InputAdornment>
                          ),
                          endAdornment: (
                            <InputAdornment position="end">
                              <IconButton
                                onClick={() => setShowPassword(!showPassword)}
                                edge="end"
                              >
                                <Box>
                                  {showPassword ? (
                                    // <HiEye className={classes.iconClass1} />
                                    <VisibilityIcon
                                      className={classes.iconClass1}
                                      style={{ width: "18px", height: "18px" }}
                                    />
                                  ) : (
                                    <img
                                      src="/images/eyeP.svg"
                                      style={{
                                        width: "18px",
                                        height: "18px",
                                        objectFit: "contain",
                                      }}
                                    />
                                  )}
                                </Box>
                              </IconButton>
                            </InputAdornment>
                          ),
                        }}
                      />
                      <FormHelperText error className={classes.errorText}>
                        {touched.password && errors.password}
                      </FormHelperText>
                    </Box>
                    {/* <Box className={classes.textFieldBox}>
                      <TextField
                        fullWidth
                        variant="outlined"
                        placeholder={t(
                          "seekerSignIn.confirmPasswordPlaceholder"
                        )}
                        type={showConfirmPassword ? "text" : "password"}
                        name="cpassword"
                        id="cpassword"
                        inputProps={{
                          maxLength: 62,
                          onCopy: (e) => e.preventDefault(), // Prevent copy
                          onPaste: (e) => e.preventDefault(), // Prevent paste
                        }}
                        value={values.cpassword}
                        error={Boolean(touched.cpassword && errors.cpassword)}
                        onBlur={handleBlur}
                        onChange={handleChange}
                        onInput={(e) => {
                          const regex = /^[^\s]*$/;
                          if (!regex.test(e.target.value)) {
                            e.target.value =
                              e.target.dataset.previousValue || "";
                          } else {
                            e.target.dataset.previousValue = e.target.value;
                          }
                        }}
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">
                              <Box display="flex" alignItems="center">
                                <img
                                  src="/images/lock1.svg"
                                  style={{ color: "#00000080" }}
                                />
                                <Divider
                                  orientation="vertical"
                                  flexItem
                                  style={{ height: "34px", margin: "0 10px" }}
                                />
                              </Box>
                            </InputAdornment>
                          ),
                          endAdornment: (
                            <InputAdornment position="end">
                              <IconButton
                                onClick={() =>
                                  setShowConfirmPassword(!showConfirmPassword)
                                }
                                edge="end"
                              >
                                <Box>
                                  {showConfirmPassword ? (
                                    // <HiEye className={classes.iconClass1} />
                                    <VisibilityIcon
                                      className={classes.iconClass1}
                                      style={{ width: "18px", height: "18px" }}
                                    />
                                  ) : (
                                    <img
                                      src="/images/eyeP.svg"
                                      style={{
                                        width: "18px",
                                        height: "18px",
                                        objectFit: "contain",
                                      }}
                                    />
                                  )}
                                </Box>
                              </IconButton>
                            </InputAdornment>
                          ),
                        }}
                      />
                      <FormHelperText error className={classes.errorText}>
                        {touched.cpassword && errors.cpassword}
                      </FormHelperText>
                    </Box> */}

                    <Grid
                      container
                      direction="row"
                      wrap="nowrap"
                      alignItems="flex-start"
                      className={classes.termsConditionWrapper}
                      style={{}}
                    >
                      <Grid item alignItems="center">
                        <FormControlLabel
                          style={{ height: "20px" }}
                          control={
                            <Checkbox
                              name="termsandcondition"
                              id="termsandcondition"
                              className={classes.checkbox}
                              checked={values.termsandcondition}
                              onChange={(e) => {
                                const newCheckedValue = e.target.checked;
                                setFieldValue(
                                  "termsandcondition",
                                  newCheckedValue
                                );
                                handleChecked(newCheckedValue);
                              }}
                            />
                          }
                        />
                      </Grid>
                      <Grid item>
                        <Typography
                          className={classes.termscondition}
                          variant="h6"
                          style={{ color: "#000000CC" }}
                        >
                          {t("seekerSignIn.termsCondition")}&nbsp;
                          <a
                            href="/terms&condition"
                            target="_blank"
                            rel="noopener noreferrer"
                            style={{ color: "#3F76D1", textDecoration: "none" }}
                          >
                            {t("seekerSignIn.terms")}
                          </a>
                          &nbsp; {t("seekerSignIn.and")}&nbsp;
                          <a
                            href="/privacy-policy"
                            target="_blank"
                            rel="noopener noreferrer"
                            style={{ color: "#3F76D1", textDecoration: "none" }}
                          >
                            &nbsp;{t("seekerSignIn.privacyPolicy")}
                          </a>
                          .
                        </Typography>
                      </Grid>
                    </Grid>
                    <FormHelperText error className={classes.errorText} >
                      {touched.termsandcondition && errors.termsandcondition}
                    </FormHelperText>

                    <Box>
                      <Button
                        className={classes.createbtn}

                        variant="contained"
                        color="secondary"
                        fullWidth
                        type="submit"
                      >
                        {t("seekerSignIn.createAccountButton")}
                        {isLoading && (
                          <CircularProgress
                            size={20}
                            style={{ color: "#fff", marginLeft: "10px" }}
                          />
                        )}
                      </Button>
                    </Box>

                    <Box
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        gap: "24px",
                        justifyContent: "center",
                        alignItems: "center",
                        marginTop: "24px",
                      }}
                    >
                      <Typography variant="h5">
                        {t("seekerSignIn.orText")}
                      </Typography>
                      <OAuth />

                      <Typography variant="h5">
                        {t("seekerSignIn.alreadyHaveAccount")}
                        <a
                          style={{
                            color: "#5E58E4",
                            cursor: "pointer",
                            marginLeft: "5px",
                            textDecoration: "none",
                          }}
                          onClick={handleLoginOpen}
                        >
                          {t("seekerSignIn.loginText")}
                        </a>
                        <Login
                          open={openLogin}
                          //   setEmail={setEmail}
                          onClose={handleLoginClose}
                        />
                      </Typography>
                    </Box>
                  </Form>
                )}
              </Formik>
            </Box>
          </Box>
          <Box className={classes.imageSection}>
            <img
              src="images/signupBanner.png"
              alt="Illustration"
              className={classes.image}
            />
            <Box className={classes.staticContent}>
              <p className={classes.staticheading1}>
                {t("seekerSignIn.staticHeading1")}
              </p>
              <h1 className={classes.staticheading2}>
                {t("seekerSignIn.staticHeading2")}
              </h1>
            </Box>
          </Box>
        </Box>
      </Popup>
      <OTPFlow open={openOTP} onClose={handleOTPClose} />
    </>
  );
};

export default SignUp;
