import React, { createContext, useContext, useEffect, useState } from "react";


export const BotContext = createContext();

export default function BotProvider(props) {


    const [responses, setResponses] = useState({});
  const contextData = {
    responses,
    setResponses
  };

  return (
    <BotContext.Provider value={contextData}>
      {props.children}
    </BotContext.Provider>
  );
}
