import React, { useEffect, useState } from "react";
import {
  Toolbar,
  IconButton,
  Typography,
  Drawer,
  List,
  ListItem,
  ListItemText,
  Box,
  Avatar,
  Popover,
  ClickAwayListener,
  Link,
  ListItemIcon,
} from "@material-ui/core";
import MenuIcon from "@material-ui/icons/Menu";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import ExpandLessIcon from "@material-ui/icons/ExpandLess";
import NotificationsNoneIcon from "@material-ui/icons/NotificationsNone";
import { makeStyles } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { useHistory, useLocation } from "react-router-dom";
import MegaMenu from "../MegaMenu";
import CloseIcon from "@material-ui/icons/Close";
import Logo from "../../assets/logoipsum.svg";
import { useTranslation } from "react-i18next";
import NoNotificationImg from "../../assets/NoNotificationImg.png";
import ChangePassword from "../../views/pages/recruiter/changePassword/ChangePassword";
import NotificationBell from "./NotificationCount";
import ResponsiveLogoutDialog from "../modals/LogoutModal";
import DeleteProfile from "../modals/DeleteProfile";
import {
  getDataHandlerWithToken,
  putDataHandlerWithToken,
} from "../../config/service";
import { format, formatDistanceToNow } from "date-fns";
import LockIcon from "@material-ui/icons/Lock";
import {
  MenuItem,
  Select,
  FormControl,
  InputLabel,
  useTheme,
} from "@mui/material";

const useStyles = makeStyles((theme) => ({
  menuItems: {
    cursor: "pointer",
    display: "flex",
    justifyContent: "flex-end",
    gap: "30px",
    alignItems: "center",
    position: "relative",
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
      alignItems: "flex-start",
      gap: "15px",
    },
  },
  menuBtn: {
    display: "flex",
    justifyContent: "flex-end",
    gap: "22px",
    alignItems: "center",
  },
  drawerList: {
    width: 250,
    height: "100%",
  },
  drawerListItem: {
    padding: "16px 24px",
    borderBottom: `1px solid ${theme.palette.divider}`,
    "&:hover": {
      backgroundColor: theme.palette.action.hover,
    },
  },
  drawerListItemText: {
    fontSize: "16px",
    color: theme.palette.text.primary,
  },
  selectedItem: {
    backgroundColor: "red", // Replace with your selected background color
    color: "green", // Replace with your selected text color
  },
  drawerHeader: {
    padding: "16px 24px",
    fontSize: "20px",
    fontWeight: "bold",
    borderBottom: `1px solid ${theme.palette.divider}`,
  },
  navbar: {
    padding: "18px",
    paddingLeft: "26px",
    paddingRight: "26px",
    backgroundColor: "#fff",
    [theme.breakpoints.down("sm")]: {
      paddingLeft: "20px",
      paddingRight: "20px",
    },
    // boxShadow: "0px 12px  #0000000D",
  },
  toolbar: {
    paddingLeft: "0px",
    paddingRight: "0px",
  },
  iconColor: {
    color: "#000000CC",
    opacity: "0.9",
  },
  logoBox: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    cursor: "pointer",
  },
  logo: {
    height: "60px",
    width: "60px",
    border: "1px solid #5E58E4",
    borderRadius: "50px",
    background:
      "radial-gradient(50% 50% at 50% 50%,  #5E58E4 50%, #5E58E4 100%)",
    color: "white",
    marginRight: "20px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    textAlign: "center",
  },
  avatar: {
    width: "54px",
    height: "54px",
    objectFit: "contain",
    // marginBottom: theme.spacing(1),
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  notification: {
    width: "24px",
    height: "28px",
  },

  flexContainer: {
    padding: "20px",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  notificationPopup: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
    width: "450px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    padding: "20px",
  },
  heading: {
    fontWeight: 700,
  },
  heading1: {
    fontWeight: 500,
    color: "#5E58E4",
  },
  notificationBox: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "row",
    padding: "10px",
    // paddingBottom:"10px",
    width: "100%",
    marginBottom: "10px",

    position: "relative",
    backgroundColor: "#F5F7FC",
    borderRadius: "10px",
    transition:
      "transform 0.3s ease, box-shadow 0.3s ease, background 0.3s ease",

    "&:hover": {
      transform: "scale(1.007)",
      // Slightly scale up the box
      boxShadow: "0px 4.8px 14.41px 0px #00000033",
      background:
        "linear-gradient(white, white) padding-box, linear-gradient(140.68deg, #5E58E4 9.76%, rgba(31, 152, 116, 0.46) 52.53%) border-box",
      border: "1px solid transparent",
    },
  },
  closeIcon: {
    height: "25px",
    width: "25px",
    position: "absolute",
    top: "-10px",
    right: "-11px",
  },
  footer: {
    display: "flex",
    justifyContent: "space-between",
    width: "100%",
    marginTop: theme.spacing(1),
  },
  footerLinkBox: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    paddingBottom: "2%",
  },
  footerLink: {
    padding: "4px",
    textDecoration: "none",
    cursor: "pointer",
    color: "#0061E0",
    textAlign: "center",
  },
  noNotificationsContainer: {
    // Add style for no notifications container
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: "20px",
    textAlign: "center",
  },
  noNotificationsImage: {
    // Add style for no notifications image
    width: "auto",
    height: "100%",
    objectFit: "contain",
    marginBottom: "10px",
  },
  noNotificationsText: {
    // Add style for no notifications text
    fontSize: "16px",
    color: theme.palette.text.secondary,
  },
  popoverPaper: {
    borderRadius: "10px", // Add your desired borderRadius here
  },
  caption: {
    fontSize: "10px",
    fontWeight: 400,
    lineHeight: "12.1px",
  },
  sideBarList: {
    // border: '1px solid red',
    padding: 0,
  },
  listItem: {
    display: "flex",
    gap: "20px",
    alignItems: "center",
    paddingLeft: "26px",
    paddingRight: "26px",
    paddingTop: "15px",
    paddingBottom: "15px",
    transition: "background-color 0.3s, color 0.3s",
    "&:hover": {
      backgroundColor: "#5E58E4",
      color: "white", // Change text color on hover
      "& $listItemIcon img": {
        filter: "brightness(0) invert(1)", // Change icon color to white on hover
      },
      "& $listItemText": {
        color: "white", // Ensure text color changes to white
      },
    },
  },
  listItemIcon: {
    height: "24px",
    width: "24px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    objectFit: "contain",
  },
  listItemText: {
    fontSize: 16,
    fontWeight: "400",
    lineHeight: "21px",
    fontFamily: "Inter",
    color: "#1E252E",
  },
  selected: {
    backgroundColor: "#5E58E4",
    color: "#FFFFFF",
    fontSize: "16px",
    "& $listItemIcon img": {
      filter: "brightness(0) invert(1)", // Change icon color for selected item
    },
    "& $listItemText": {
      color: "#FFFFFF",
      fontWeight: "500",
      fontSize: "16px",
    },
  },
}));

const RecruiterLoginHeader = () => {
  const classes = useStyles();
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [isMegaMenuOpen, setIsMegaMenuOpen] = useState(false);
  const [notificationAnchorEl, setNotificationAnchorEl] = useState(null);
  const [selectedItem, setSelectedItem] = useState("");
  const [isChangePasswordModalOpen, setChangePasswordModalOpen] =
    useState(false);
  const { t, i18n } = useTranslation();

  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [profile, setProfile] = useState();
  const location = useLocation();
  const language = sessionStorage.getItem("language") || "en";
  // const getProfile = async () => {
  //   try {

  //     const res = await getDataHandlerWithToken("getRProfile");
  //     console.log(res.result?.companyName);
  //     if (res?.responseCode === 200) {
  //       setProfile(res?.result?.companyLogo);
  //     }
  //   } catch (error) {
  //     console.log("error while getting profile", error);
  //   }
  // };
  // useEffect(() => {
  //   getProfile();
  // }, []);

  const handleDeleteprofile = () => {
    setOpenDeleteModal(true);
  };

  const handleCloseModal = () => {
    setOpenDeleteModal(false); // Close the delete modal
  };

  const handleOpenChangePasswordModal = () => {
    setChangePasswordModalOpen(true);
  };

  const handleCloseChangePasswordModal = () => {
    setChangePasswordModalOpen(false);
  };
  const handleLogout = () => {
    setLogoutModalOpen(true); // Open DeleteJobModal when "Cancel Plan" is clicked
  };
  const subscriptionStatus =
    sessionStorage.getItem("subscriptionStatus") === "ACTIVE";
  const handleListItemClick = (item) => {
    if (item.disabled) return; // Prevent action if disabled

    if (item.action) {
      item.action(); // Execute custom action if defined
    } else if (item.path) {
      history.push(item.path); // Navigate to path if defined
    }
  };
  const [logoutModalOpen, setLogoutModalOpen] = useState(false);

  const history = useHistory();

  const handleDrawerToggle = () => {
    setDrawerOpen(!drawerOpen);
  };

  const toggleMegaMenu = () => {
    setIsMegaMenuOpen(!isMegaMenuOpen);
  };
  const handleNotificationNav = (notification) => {
    // if (notification.type === "APPLICATION") {
    //   // Redirect to application review page
    //   navigateTo("/applications");
    // } else if (notification.type === "INFO") {
    //   // Redirect to informational section
    //   navigateTo("/info");
    // }
    // // Add other types as necessary
  };

  const isMobile = useMediaQuery((theme) => theme.breakpoints.down("sm"));

  const handleNavigation = (text) => {
    setSelectedItem(text);
    switch (text) {
      // case "Home":
      //   history.push('/recruiterlandingpage');
      //   break;
      case "Dashboard":
        history.push("/recruiterDashboard");
        break;
      case "Company Profile":
        history.push("/CompanyProfile");
        break;
      case "Post Jobs":
        history.push("/postNewJob");
        break;
      case "Support":
        history.push("/support");
        break;
      case "Post A New Job":
        history.push("/postNewJob");
        break;
      case "Post A New Job":
        history.push("/postNewJob");
        break;
      case "Saved Candidates":
        // history.push("/allApplicants");
        break;
      case "Shortlisted Resume":
        history.push("/shortlisted-resume");
        break;
      case "Subscriptions":
        history.push("/subscription");
        break;
      case "Chats":
        history.push("/chats");
        break;
      case "Change Password":
        handleOpenChangePasswordModal();
        break;
      case "Manage Job":
        history.push("/ManageJob");
        break;
      case "Pricing":
        history.push("/pricings");
        break;
      case "Logout":
        setLogoutModalOpen(true); // Open the logout modal when Logout is clicked
        break;
      case "Delete Profile":
        onClick: handleDeleteprofile();
        break;
      default:
        break;
    }
  };

  const handleNotificationClick = (event) => {
    setNotificationAnchorEl(event.currentTarget);
  };

  const handleCloseNotification = () => {
    setNotificationAnchorEl(null);
  };

  const handleLogoutModalClose = () => {
    setLogoutModalOpen(false); // Close the logout modal
  };

  const open = Boolean(notificationAnchorEl);

  const [notifications, setNotifications] = useState();

  const fetchNotifications = async () => {
    const query = {
      recruiterId: localStorage.getItem("id"),
      limit: Number.MAX_SAFE_INTEGER,
      preferredLanguage: language,
    };
    try {
      const res = await getDataHandlerWithToken("getAllNotifications", query);
      if (res?.responseCode === 200) {
        // Filter notifications based on the 'NEW' status in the recruiter's data
        const newNotifications = res.result.notifications.filter(
          (notification) =>
            notification.recruiter.some(
              (recruiter) => recruiter.status === "NEW"
            )
        );
        setNotifications(newNotifications);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    fetchNotifications();
    const interval = setInterval(fetchNotifications, 10000);
    return () => clearInterval(interval);
  }, [language]);

  // Function to handle the delete action
  const handleDelete = async (id) => {
    const data = {
      recruiterId: localStorage.getItem("id"),
      notificationIds: [id],
      status: "DELETE",
    };

    try {
      const res = await putDataHandlerWithToken(
        "updateNotificationStatus",
        data
      );
      if (res.responseCode === 200) {
        fetchNotifications();
      }
    } catch (error) {
      console.log(error);
    }

    // setNotifications(
    //   notifications.filter((notification) => notification.id !== id)
    // );
  };
  const handleClearAll = async () => {
    const data = {
      recruiterId: localStorage.getItem("id"),
      notificationIds:
        notifications?.map((notification) => notification._id) || [],

      status: "READ",
    };

    console.log(data);

    try {
      const res = await putDataHandlerWithToken(
        "updateNotificationStatus",
        data
      );
      if (res.responseCode === 200) {
        fetchNotifications();
      }
    } catch (error) {
      console.log(error);
    }
  };

  const menuItems = [
    {
      text: t("recruiterMenu.dashboard"), // Translated text
      icon: "/images/DashboardIcon.svg",
      iconOnFocus: "/images/DashboardIcon.svg",
      path: "/recruiterDashboard",
      disabled: false,
    },
    {
      text: t("recruiterMenu.companyProfile"), // Translated text
      icon: "/images/ProfileBlackIcon.svg",
      iconOnFocus: "/images/ProfileBlackIcon.svg",
      path: "/CompanyProfile",
      disabled: false,
    },
    {
      text: t("recruiterMenu.postNewJob"), // Translated text

      icon: "/images/PostJobBlackIcon.svg",
      iconOnFocus: "/images/PostJobBlackIcon.svg",
      path: "/postNewJob",
      disabled: !subscriptionStatus,
    },
    {
      text: t("recruiterMenu.manageJobs"), // Translated text
      icon: "/images/ManageJobBlackIcon.svg",
      iconOnFocus: "/images/ManageJobBlackIcon.svg",
      path: "/ManageJob",
      disabled: !subscriptionStatus,
    },
    {
      text: t("recruiterMenu.allApplicants"), // Translated text
      icon: "/images/ApplicantBlackIcon.svg",
      iconOnFocus: "/images/ApplicantBlackIcon.svg",
      path: "/allApplicants",
      disabled: !subscriptionStatus,
    },
    {
      text: t("recruiterMenu.shortlistedResumes"), // Translated text
      icon: "/images/SavedBlackIcon.svg",
      iconOnFocus: "/images/SavedBlackIcon.svg",
      path: "/shortlisted-resume",
      disabled: !subscriptionStatus,
    },
    {
      text: t("recruiterMenu.subscriptions"), // Translated text
      icon: "/images/SubscriptionBlackIcon.svg",
      iconOnFocus: "/images/SubscriptionBlackIcon.svg",
      path: "/subscription",
      disabled: !subscriptionStatus,
    },
    {
      text: t("recruiterMenu.chats"), // Translated text
      icon: "/images/ChatBlackIcon.svg",
      iconOnFocus: "/images/ChatBlackIcon.svg",
      path: "/chats",
      disabled: !subscriptionStatus,
    },
    {
      text: t("recruiterMenu.changePassword"), // Translated text
      icon: "/images/PasswordBlackIcon.svg",
      iconOnFocus: "/images/PasswordBlackIcon.svg",
      action: handleOpenChangePasswordModal, // Directly handle opening the modal
    },
    {
      text: t("recruiterMenu.logout"), // Translated text
      icon: "/images/LogoutBlackIcon.svg",
      iconOnFocus: "/images/LogoutBlackIcon.svg",
      // onClick: handleClickOpen, // Open the dialog on Logout
      action: handleLogout,
      disabled: false,
    },
    {
      text: t("recruiterMenu.deleteProfile"), // Translated text
      icon: "/images/DeleteBlackIcon.svg",
      iconOnFocus: "/images/DeleteBlackIcon.svg",
      action: handleDeleteprofile,
      disabled: false,
    },
  ];

  const drawerList = (
    <Box
      className={classes.drawerList}
      role="presentation"
      onClick={handleDrawerToggle}
      onKeyDown={handleDrawerToggle}
    >
      <List className={classes.sideBarList}>
        {menuItems.map((item, index) => (
          <ListItem
            button
            key={index}
            disabled={item.disabled}
            className={`${classes.listItem} ${
              item.path && location.pathname.startsWith(item.path)
                ? classes.selected
                : ""
            }`}
            onClick={() => handleListItemClick(item)}
          >
            <ListItemIcon className={classes.listItemIcon}>
              <img
                src={
                  location.pathname.startsWith(item.path) && item.iconOnFocus
                    ? item.iconOnFocus
                    : item.icon
                }
                alt="itemIcon"
                style={{ objectFit: "contain" }}
              />
            </ListItemIcon>
            <ListItemText
              primary={item.text}
              classes={{
                primary: item.disabled
                  ? `${classes.listItemText} ${classes.disabled}`
                  : classes.listItemText,
              }}
            />

            {item.disabled && <LockIcon className={classes.lockIcon} />}
          </ListItem>
        ))}
      </List>
    </Box>
  );
  const handleChange = async (event) => {
    const language = event.target.value;
    const data = {
      preferredLanguage: language,
    };
    try {
      const res = await putDataHandlerWithToken("editProfile", data);
      if (res.responseCode === 200) {
        sessionStorage.setItem("language", language);
        await i18n.changeLanguage(language);
      }
    } catch (error) {
      console.log(error);
    }
  };
  const handleNotificationClickOpen = () => {
    history.push("/recruiterNotification"); // Change '/home' to your home route
  };

  const handleLogoClick = () => {
    history.push("/recruiterlandingpage");
  };

  return (
    <>
      <Box className={classes.navbar}>
        <Toolbar className={classes.toolbar}>
          {isMobile && (
            <IconButton
              edge="start"
              color="inherit"
              aria-label="menu"
              onClick={handleDrawerToggle}
            >
              <MenuIcon />
            </IconButton>
          )}
          <Box onClick={handleLogoClick} className={classes.logoBox}>
            <img src={Logo} alt="logo" />
          </Box>
          {!isMobile && (
            <Box className={classes.menuItems} flexGrow={1}>
              {["Dashboard", "Post Jobs", "Pricing", "Support"].map((text) => (
                <Box
                  display="flex"
                  alignItems="center"
                  key={text}
                  onClick={() => handleNavigation(text)}
                  sx={{ cursor: "pointer" }}
                >
                  <Typography variant="h4" display="inline" color="#000000CC">
                    {text}
                  </Typography>
                </Box>
              ))}
              <Box className={classes.menuBtn}>
                <ClickAwayListener onClickAway={handleCloseNotification}>
                  <div>
                    <Box
                      className={classes.noificationBox}
                      onClick={handleNotificationClick}
                    >
                      {/* <NotificationsNoneIcon
                        className={classes.notification}
                        style={{ color: "#5E58E4" }}
                      /> */}
                      <NotificationBell
                        notificationCount={notifications?.length}
                      />
                    </Box>
                    <Popover
                      open={open}
                      anchorEl={notificationAnchorEl}
                      onClose={handleCloseNotification}
                      anchorOrigin={{
                        vertical: "bottom",
                        horizontal: "center",
                      }}
                      transformOrigin={{
                        vertical: "top",
                        horizontal: "center",
                      }}
                      PaperProps={{
                        className: classes.popoverPaper,
                      }}
                    >
                      <Box my={0} className={classes.flexContainer}>
                        <Typography variant="h4" className={classes.heading}>
                          Notifications
                        </Typography>
                        {notifications?.length > 0 && (
                          <Typography
                            variant="h6"
                            className={classes.heading1}
                            onClick={handleClearAll}
                            style={{ cursor: "pointer", color: "#0E1D66" }}
                          >
                            Clear All
                          </Typography>
                        )}
                      </Box>
                      <div className={classes.notificationPopup}>
                        {notifications?.length > 0 ? (
                          notifications
                            .sort((a, b) => {
                              // Place "NEW" notifications on top
                              if (
                                a.recruiter.some((r) => r.status === "NEW") &&
                                !b.recruiter.some((r) => r.status === "NEW")
                              ) {
                                return -1;
                              }
                              if (
                                !a.recruiter.some((r) => r.status === "NEW") &&
                                b.recruiter.some((r) => r.status === "NEW")
                              ) {
                                return 1;
                              }
                              // If both are "NEW" or both are not, sort by createdAt in descending order
                              return (
                                new Date(b.createdAt) - new Date(a.createdAt)
                              );
                            })
                            .map((notification) => (
                              <Box
                                key={notification._id}
                                className={classes.notificationBox}
                              >
                                <Box>
                                  <Typography
                                    variant="h6"
                                    style={{
                                      fontWeight: 500,
                                      color: "rgba(0, 0, 0, 0.8)",
                                      fontSize: "12px",
                                      lineHeight: "14.52px",
                                    }}
                                    onClick={() =>
                                      handleNotificationNav(notification)
                                    }
                                  >
                                    {notification.message}
                                  </Typography>
                                  <div className={classes.footer}>
                                    <Typography
                                      variant="caption"
                                      className={classes.caption}
                                    >
                                      {format(
                                        new Date(notification.createdAt),
                                        "EEEE, MMM d yyyy 'at' hh:mm a"
                                      )}
                                    </Typography>
                                    <Typography
                                      variant="caption"
                                      style={{ color: "rgba(0, 0, 0, 0.6)" }}
                                    >
                                      {formatDistanceToNow(
                                        new Date(notification.createdAt),
                                        {
                                          addSuffix: true,
                                        }
                                      )}
                                    </Typography>
                                  </div>
                                </Box>
                                <IconButton
                                  className={classes.closeIcon}
                                  onClick={() =>
                                    handleDelete(notification?._id)
                                  }
                                >
                                  <CloseIcon
                                    style={{
                                      fontSize: "0.9rem",
                                      color: "black",
                                    }}
                                  />
                                </IconButton>
                              </Box>
                            ))
                        ) : (
                          <Box className={classes.noNotificationsContainer}>
                            <img
                              src={NoNotificationImg}
                              alt="No notifications"
                              className={classes.noNotificationsImage}
                            />
                            <Typography className={classes.noNotificationsText}>
                              No new notifications
                            </Typography>
                          </Box>
                        )}
                      </div>

                      <Box className={classes.footerLinkBox}>
                        <Link
                          onClick={handleNotificationClickOpen}
                          className={classes.footerLink}
                          style={{ textDecoration: "none" }}
                        >
                          View all notifications
                        </Link>
                      </Box>
                    </Popover>
                  </div>
                </ClickAwayListener>
                <FormControl sx={{ minWidth: 120 }}>
                  {/* Select Component */}
                  <Select
                    value={i18n.language}
                    onChange={handleChange}
                    sx={{
                      minWidth: 120,
                      borderRadius: "10px",
                      backgroundColor: "#fff",
                      // color: theme.palette.text.primary,
                      "& .MuiSelect-icon": {
                        color: "#5E58E4",
                      },
                      "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                        borderColor: "#5E58E4",
                        "&:hover .MuiOutlinedInput-notchedOutline": {
                          borderColor: "#5E58E4",
                        },
                      },
                    }}
                  >
                    <MenuItem value="en">English</MenuItem>
                    <MenuItem value="ar">عربي</MenuItem>
                  </Select>
                </FormControl>
                <Avatar
                  className={classes.avatar}
                  src={profile}
                  alt="User Name"
                />
              </Box>
              <MegaMenu isOpen={isMegaMenuOpen} />
            </Box>
          )}
        </Toolbar>
        <Drawer
          anchor="left"
          open={isMobile ? drawerOpen : false}
          onClose={handleDrawerToggle}
        >
          {drawerList}
        </Drawer>
        <ChangePassword
          open={isChangePasswordModalOpen}
          onClose={handleCloseChangePasswordModal}
          message="Your new Password must be different form previous used Password."
          modalTitle="Change Password"
          buttonText="Save"
        />
        <ResponsiveLogoutDialog
          open={logoutModalOpen}
          onClose={handleLogoutModalClose}
        />
        <DeleteProfile
          open={openDeleteModal}
          onClose={handleCloseModal}
          Img="./images/dustbin.png"
          message={t("confirmDeleteProfile")}
          buttonText1={t("no")}
          buttonText2={t("yes")}
        />
      </Box>
    </>
  );
};

export default RecruiterLoginHeader;
