import React from 'react';
import Badge from '@material-ui/core/Badge'; // Import Badge component
import NotificationsIcon from '@material-ui/icons/Notifications'; // Import Bell icon
import { makeStyles } from '@material-ui/core/styles';
import NotificationsNoneIcon from "@material-ui/icons/NotificationsNone";

// Define custom styles
const useStyles = makeStyles((theme) => ({
  badge: {
    right: 0, // Position the badge slightly to the right
    top: 10, // Position the badge slightly to the top
    border: `2px solid ${theme.palette.background.paper}`, // Add a border to the badge
    padding: 0, // Padding inside the badge
    backgroundColor: 'red', // Set badge background color to red
    color: 'white', // Set badge text color to white
    fontSize: 10
  },
}));

const NotificationBell = ({ notificationCount }) => {
  const classes = useStyles(); // Use custom styles

  return (
    <Badge
      badgeContent={notificationCount} // Set the notification count as the badge content
      color="secondary"
      classes={{ badge: classes.badge }} // Apply custom styles to the badge
    >
      <NotificationsIcon
        className={classes.notification}
        style={{ color: "#5E58E4", fontSize: '25px' }}
    />
    </Badge>
  );
};

export default NotificationBell;
