import React, { Suspense, Fragment, useEffect, useState } from "react";
import { Router, Switch, Route } from "react-router-dom";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import MomentUtils from "@date-io/moment";
import { routes } from "./routes";
import { createBrowserHistory } from "history";
import AuthContext from "./context/Auth";
import RecruitAuthContext from "./context/recruiterAuth";
import PageLoading from "./component/PageLoading";
import AuthGuard from "./config/AuthGuard";
import { ThemeProvider } from "@material-ui/core";
import { createMuiTheme } from "./theme";
import { Toaster } from "react-hot-toast";
import JobContext from "./context/JobContext";
import BotProvider from "./context/BotContext";
import ProfileProvider from "./context/ProfileContext";
import AuthProvider from "./context/Auth";
import RecruiterGuard from "./config/RecruiterGuard";
import { useTranslation } from "react-i18next";
import { BrowserRouter } from "react-router-dom";

// import dotenv from "dotenv";

// dotenv.config();

function App() {
  const history = createBrowserHistory();
  const [direction, setDirection] = useState("rtl");

  const { i18n } = useTranslation();
  useEffect(() => {
    const newDirection = i18n.language === "ar" ? "rtl" : "ltr";
    setDirection(newDirection);
    document.documentElement.lang = i18n.language;
    document.documentElement.dir = newDirection;
    document.body.style.direction = newDirection;
    if (newDirection === "rtl") {
      document.body.style.textAlign = "right";
      document.documentElement.setAttribute("dir", "rtl");
    } else {
      document.body.style.textAlign = "left";
      document.documentElement.setAttribute("dir", "ltr");
    }
  }, [i18n.language]);

  const theme = createMuiTheme({ direction });
  return (
    <div className="App">
      <BrowserRouter>
        <ThemeProvider theme={theme}>
          <MuiPickersUtilsProvider utils={MomentUtils}>
            <AuthContext>
              <RecruitAuthContext>
                <JobContext>
                  <BotProvider>
                    <ProfileProvider>
                      <AuthProvider>
                        <RenderRoutes data={routes} />
                      </AuthProvider>
                    </ProfileProvider>
                  </BotProvider>
                </JobContext>
              </RecruitAuthContext>
            </AuthContext>
          </MuiPickersUtilsProvider>
        </ThemeProvider>
      </BrowserRouter>
    </div>
  );
}

function RenderRoutes({ data }) {
  return (
    <Suspense fallback={<PageLoading />}>
      <Switch>
        {data.map((route, i) => {
          const Component = route.component;
          const Guard =
            route.type === "seekar"
              ? AuthGuard
              : route.type === "recruiter"
                ? RecruiterGuard
                : Fragment;
          const Layout = route.layout || Fragment;
          return (
            <Route
              exact={route.exact}
              key={i}
              path={route.path}
              render={(props) => (
                <Guard>
                  <Toaster position="top-right" />
                  <Layout>
                    {route.routes ? (
                      <RenderRoutes data={route.routes} />
                    ) : (
                      <Component {...props} />
                    )}
                  </Layout>
                </Guard>
              )}
            />
          );
        })}
      </Switch>
    </Suspense>
  );
}

export default App;
