import React, { useState, useEffect } from "react";
import {
  Box,
  Button,
  Typography,
  Paper,
  FormControl,
  styled,
  makeStyles,
  Modal,
  IconButton,
  CircularProgress,
} from "@material-ui/core";
import { useHistory } from "react-router-dom";
import CloseIcon from "@material-ui/icons/Close";
import OTPInput from "otp-input-react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import CompleteProfile from "./CompleteProfile";
import Popup from "../../../../component/modals/LoginModal";
import ResetPassword from "./ResetPassword";
import { postApiHandler } from "../../../../config/service";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles((theme) => ({
  authContainer: {
    "& .MuiOutlinedInput-notchedOutline": {
      borderColor: "#AFB0B6",
    },

    display: "flex",
    flexDirection: "row",
  },
  formContainer: {
    borderRadius: "20px 0px 0px 20px",
    backgroundColor: "white",
    padding: theme.spacing(4),
    "@media(max-width:1024px)": {
      padding: theme.spacing(4),

      width: "39vw",
    },
    "@media(max-width:768px)": {
      padding: theme.spacing(4),
      borderRadius: "20px",
      width: "45vw",
    },

    "@media(max-width:430px)": {
      borderRadius: "20px",
      padding: "20px",
      width: "76vw",
    },
    "@media(max-width:390px)": {
      borderRadius: "20px",
      padding: "35px",
      width: "63vw",
    },
    position: "relative",
  },
  imageSection: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    "@media(max-width:768px)": {
      display: "none",
    },
    "@media(max-width:430px)": {
      display: "none",
      borderRadius: "20px",
    },
  },
  image: {
    objectFit: "contain",
    borderRadius: "0px 20px 20px 0px",
    height: "100%",

    "@media(max-width:768px)": {
      width: "100%",
      objectFit: "contain",
      height: "100%",
      borderRadius: "0px 20px 20px 0px",
    },
  },
  staticContent: {
    top: "51px",
    width: "29%",
    position: "absolute",
    color: "white",
    padding: theme.spacing(0),
  },
  staticheading1: {
    fontWeight: 400,
    fontSize: 20,
    lineHeight: "24px",
    color: "#FFFFFF",
    fontFamily: "Inter",
    marginBottom: theme.spacing(1),
  },
  staticheading2: {
    fontWeight: 700,
    fontSize: 20,
    lineHeight: "24px",
    color: "#FFFFFF",
    fontFamily: "Inter",
  },
  heading: {
    fontWeight: 700,
    lineHeight: "29px",
    fontSize: 24,
    fontFamily: "Inter",
    color: "#262626",
    "@media(max-width:1200px)": {
      fontSize: 22,
      lineHeight: "28px",
    },
    "@media(max-width:992px)": {
      fontSize: 18,
      lineHeight: "24px",
    },
    "@media(max-width:767px)": {
      fontSize: 16,
      lineHeight: "18px",
    },
  },
  heading1: {
    lineHeight: "20px",
  },
  remember: {
    marginTop: "10px",
    display: "flex",
    justifyContent: "space-between",
    "@media(max-width:1024px)": {
      gap: "52px",
    },
    "@media(max-width:768px)": {
      gap: "42px",
    },
    "@media(max-width:430px)": {
      gap: "32px",
    },
  },

  closeButton: {
    position: "absolute",
    top: theme.spacing(-3),
    right: theme.spacing(-57),
    height: "31px",
    width: "31px",
    color: theme.palette.grey[900],
    backgroundColor: "white",
    "@media(max-width:1030px)": {
      height: "23px",
      width: "23px",
      top: "-16PX",
      right: "-18PX",
    },
    "&:hover": {
      color: theme.palette.grey[900],
      backgroundColor: "white",
    },
  },
  errorText: {
    color: "#F83D3D",
  },
  timerText: {
    color: "#5E58E4",
  },
}));

const ChamgepassBox = styled(Box)({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  boxSizing: "border-box",
  // padding: '20px 20px 0px 20px',
});

const OtpBox = styled(Box)({
  borderRadius: "10px",
  "& .mainBox": {
    background: "#fff",
    "@media (max-width:600px)": {
      padding: "0px",
    },
  },
  "& .buttonBox": {
    marginTop: "10px",
  },
  "& .textbox": {
    padding: "3px 0px 20px",
    marginTop: "10px",
  },
});

const OtpFormControl = styled(FormControl)(({ hasError }) => ({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  gap: "0px",
  marginLeft: "-2px",
  "& .MuiInput-root": {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  "& input": {
    color: "#1C1C1C",
    width: "48px !important",
    height: "52px !important",

    margin: "0px 2.5px 0px 0.5px !important",
    borderRadius: "10px",
    background: "transparent",
    border: hasError ? "1px solid red" : "1px solid #AFB0B6",
    "&:focus": {
      border: "1px solid #5E58E4 !important",
    },
    "@media(max-width:480px)": {
      margin: "0 3px !important",
      width: "37px !important",
      height: "37px !important",
    },
    "@media(max-width:390px)": {
      width: "31px !important",
      height: "31px !important",
    },
  },
}));

const ResetOTPFlow = ({ open, onClose, handleResetClick, email }) => {
  const [OTP, setOTP] = useState("");
  const [timeLeft, setTimeLeft] = useState(null);
  const [errorMessage, setErrorMessage] = useState("");
  const [openOtpSuccessDialog, setOpenOtpSuccessDialog] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isLoad, setIsLoad] = useState(false);
  const [openResendDialog, setOpenResendDialog] = useState(false);
  const [isButtonDisabled, setIsButtonDisabled] = useState(true);
  const [isTyping, setIsTyping] = useState(false); // New state to track typing
  const history = useHistory();
  const { t } = useTranslation();
  const classes = useStyles();
  const language = sessionStorage.getItem("language") || "en";
  const [openReset, setOpenReset] = React.useState(false);
  const handleResetOpen = () => {
    onClose();
    console.log("handle otp clicked", handleResetClick);

    handleResetClick();
  };
  const handleResendClick = () => {
    handleResendOTP();
    // Reset timer when OTP is resent
    setIsButtonDisabled(true);
  };
  const handleResetClose = () => setOpenReset(false);

  useEffect(() => {
    setTimeLeft(180);
  }, [open]);
  useEffect(() => {
    const countdown = setInterval(() => {
      setTimeLeft((prevTimer) => prevTimer - 1);
    }, 1000);

    if (timeLeft === 0) {
      clearInterval(countdown);

      if (open) {
        toast.error("Time expired!");
      }
    }

    return () => clearInterval(countdown);
  }, [timeLeft]);

  const handleFormSubmit = async (values) => {
    if (OTP.length !== 6) {
      const errorMessage =
        language === "en" ? "Please enter OTP." : "\u202Eيرجى إدخال رمز PTO.";

      setErrorMessage(errorMessage);
    } else {
      const data = {
        email: email,

        otp: OTP,
      };

      setIsLoading(true);

      try {
        const response = await postApiHandler("verifyOtpSeeker", data);

        if (response?.responseCode === 200) {
          toast.success(response.responseMessage);

          onClose();
          setOTP("");
          handleResetOpen();
        } else {
          setOTP("");
          toast.error(response.responseMessage);
        }
      } catch (error) {
        setOTP("");
        toast.error("OTP not verified.");

        setIsLoading(false);
      } finally {
        setIsLoading(false);
      }
    }
  };

  const handleResendOTP = async () => {
    setIsLoad(true);
    try {
      const response = await postApiHandler("resendOtpSeeker", {
        email: email,
      });

      if (response.responseCode === 200) {
        setTimeLeft(180);

        toast.success("OTP sent successfully.");
      } else {
        toast.error("Something went wrong.");
      }
    } catch (error) {
      toast.error("Failed to resend OTP. Please try again.");
      setIsLoad(false);
    } finally {
      setIsLoad(false);
    }
  };

  return (
    <>
      <Popup open={open} handleClose={onClose}>
        <Box className={classes.authContainer}>
          <Box className={classes.formContainer}>
            <ChamgepassBox>
              <OtpBox>
                <Paper className="mainBox" elevation={2}>
                  <Typography className={classes.heading}>
                    OTP{t("otpVerification.heading")}
                  </Typography>
                  <Box className="textbox">
                    <Typography
                      variant="h6"
                      style={{ lineHeight: "20px" }}
                      dangerouslySetInnerHTML={{
                        __html: t("otpVerification.description", {
                          email: email,
                        }),
                      }}
                    />
                  </Box>
                  <Box>
                    <OtpFormControl
                      hasError={!!errorMessage}
                      style={{ padding: "0px 0px 0px 0px" }}
                    >
                      <OTPInput
                        value={OTP}
                        inputVariant="standard"
                        autoComplete="off"
                        onChange={(value) => {
                          setOTP(value);
                          if (errorMessage) {
                            setErrorMessage("");
                          }
                        }}
                        autoFocus
                        OTPLength={6}
                        otpType="number"
                        secure
                      />
                    </OtpFormControl>
                    {errorMessage && (
                      <Typography variant="body2" className={classes.errorText}>
                        {errorMessage}
                      </Typography>
                    )}
                    <Box
                      display="flex"
                      justifyContent="space-between"
                      alignItems="center"
                      mt={0}
                    >
                      {timeLeft === 0 ? (
                        <Button
                          color="secondary"
                          style={{
                            textDecoration: "none",
                            color: "#5E58E4",
                            marginLeft: "-8px",
                          }}
                          onClick={handleResendClick}
                        >
                          {isLoad ? (
                            <CircularProgress size={24} color="inherit" />
                          ) : (
                            t("otpVerification.resend")
                          )}
                        </Button>
                      ) : (
                        <Button
                          color="secondary"
                          style={{
                            textDecoration: "none",
                            color: "#5E58E47c",
                            cursor: "not-allowed",
                            marginLeft: "-8px",
                          }}
                          onClick={handleResendClick}
                          disabled={isButtonDisabled}
                        >
                          {t("otpVerification.resend")}
                        </Button>
                      )}
                      <Box>
                        <Typography
                          variant="body2"
                          className={classes.timerText}
                        >
                          {Math.floor(timeLeft / 60)}:
                          {timeLeft % 60 < 10
                            ? `0${timeLeft % 60}`
                            : timeLeft % 60}
                        </Typography>
                      </Box>
                    </Box>
                  </Box>
                  <Box className="buttonBox">
                    <Button
                      style={{ backgroundColor: "#5E58E4", color: "white" }}
                      variant="contained"
                      color="secondary"
                      fullWidth
                      type="submit"
                      onClick={handleFormSubmit}
                      disabled={timeLeft === 0}
                    >
                      {isLoading ? (
                        <CircularProgress size={24} color="inherit" />
                      ) : (
                        t("otpVerification.submit")
                      )}
                    </Button>
                    <ToastContainer />
                  </Box>
                  <Typography
                    variant="body2"
                    color="secondary"
                    style={{
                      cursor: "pointer",
                      textAlign: "center",
                      textDecoration: "underline",
                    }}
                    onClick={() => history.push("/")}
                  >
                    {t("otpVerification.backToLogin")}
                  </Typography>
                </Paper>
              </OtpBox>
            </ChamgepassBox>
          </Box>
          <Box className={classes.imageSection}>
            <img
              src="images/otp.png"
              alt="Illustration"
              className={classes.image}
            />
            <Box className={classes.staticContent}>
              <p className={classes.staticheading1}>
                {t("otpVerification.goodWorksMessage")}
              </p>
              <h1 className={classes.staticheading2}>
                {t("otpVerification.heading")}
              </h1>
            </Box>
          </Box>
        </Box>
      </Popup>

      <ResetPassword open={openReset} onClose={handleResetClose} />
    </>
  );
};

export default ResetOTPFlow;
