import * as Yup from "yup";
import { useTranslation } from "react-i18next";
const zipRegex = /^[A-Za-z0-9]{2,10}([- ]?[A-Za-z0-9]{2,10})?$/;
const passwordRegex = /^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d]{8,}$/;
const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
const fullNameRegex = /^[A-Za-z\s\u0600-\u06FF]{1,50}$/;

const companyregex =
  /^(?!\s)(?!.*\s{2,})[A-Za-z0-9!@#$%^&*()_+={}\[\]|\\:;"'<>,.?/-]+(?=\S)$/;

export const PostJobSchema = () => {
  const { t } = useTranslation();

  return Yup.object({
    jobTitle: Yup.string()
      .required(t("postJobValidation.postJob.jobTitle.required"))
      .trim(),

    jobDescription: Yup.string()
      .required(t("postJobValidation.postJob.jobDescription.required"))
      .trim()
      .min(
        100,
        t("postJobValidation.postJob.jobDescription.min", { min: 100 })
      ),

    skills: Yup.array()
      .of(Yup.string().required(t("postJobValidation.postJob.skills.required")))
      .min(1, t("postJobValidation.postJob.skills.min")),

    jobType: Yup.string()
      .required(t("postJobValidation.postJob.jobType.required"))
      .trim(),

    salary: Yup.object({
      currency: Yup.string()
        .required(t("postJobValidation.postJob.salary.currency.required"))
        .trim(),
      minSalary: Yup.number()
        .required(t("postJobValidation.postJob.salary.minSalary.required"))
        .positive(t("postJobValidation.postJob.salary.minSalary.positive"))
        .integer(t("postJobValidation.postJob.salary.minSalary.integer")),
      maxSalary: Yup.number()
        .required(t("postJobValidation.postJob.salary.maxSalary.required"))
        .positive(t("postJobValidation.postJob.salary.maxSalary.positive"))
        .integer(t("postJobValidation.postJob.salary.maxSalary.integer"))
        .moreThan(
          Yup.ref("minSalary"),
          t("postJobValidation.postJob.salary.maxSalary.moreThan")
        ),
    }),

    experience: Yup.object({
      minExperience: Yup.number()
        .required(
          t("postJobValidation.postJob.experience.minExperience.required")
        )
        .positive(
          t("postJobValidation.postJob.experience.minExperience.positive")
        )
        .integer(
          t("postJobValidation.postJob.experience.minExperience.integer")
        ),
      maxExperience: Yup.number()
        .required(
          t("postJobValidation.postJob.experience.maxExperience.required")
        )
        .positive(
          t("postJobValidation.postJob.experience.maxExperience.positive")
        )
        .integer(
          t("postJobValidation.postJob.experience.maxExperience.integer")
        )
        .moreThan(
          Yup.ref("minExperience"),
          t("postJobValidation.postJob.experience.maxExperience.moreThan")
        ),
    }),

    careerLevel: Yup.string()
      .required(t("postJobValidation.postJob.careerLevel.required"))
      .trim(),

    gender: Yup.string()
      .required(t("postJobValidation.postJob.gender.required"))
      .trim(),

    industry: Yup.string()
      .required(t("postJobValidation.postJob.industry.required"))
      .trim(),
    industrySector: Yup.string()
      .required(t("postJobValidation.postJob.industrySector.required"))
      .trim(),
    jobCategory: Yup.string()
      .required(t("postJobValidation.postJob.jobCategory.required"))
      .trim(),
    city: Yup.string()
      .required(t("postJobValidation.postJob.city.required"))
      .trim(),
    openings: Yup.number()
      .typeError(t("postJobValidation.postJob.openings.typeError"))
      .required(t("postJobValidation.postJob.openings.required"))
      .positive(t("postJobValidation.postJob.openings.positive"))
      .integer(t("postJobValidation.postJob.openings.integer")),

    qualification: Yup.array()
      .of(
        Yup.string().required(
          t("postJobValidation.postJob.qualification.required")
        )
      )
      .min(1, t("postJobValidation.postJob.qualification.min")),

    country: Yup.string()
      .required(t("postJobValidation.postJob.country.required"))
      .trim(),

    deadlineDate: Yup.string()
      .required(t("postJobValidation.postJob.deadlineDate.required"))
      .trim(),

    findOnMap: Yup.string().optional().trim(),

    latitude: Yup.string()
      .optional()
      .trim()
      .matches(
        /^(-?([0-8]?[0-9]|90)(\.\d+)?|(\d+))$/,
        t("postJobValidation.postJob.latitude.matches")
      )
      .test(
        "is-valid-latitude",
        t("postJobValidation.postJob.latitude.range"),
        (value) => {
          if (!value) return true;
          const numericValue = parseFloat(value);
          return numericValue >= -90 && numericValue <= 90;
        }
      ),

    longitude: Yup.string()
      .optional()
      .trim()
      .matches(
        /^(-?(180|1[0-7][0-9]|[0-9]{1,2})(\.\d+)?|(\d+))$/,
        t("postJobValidation.postJob.longitude.matches")
      )
      .test(
        "is-valid-longitude",
        t("postJobValidation.postJob.longitude.range"),
        (value) => {
          if (!value) return true;
          const numericValue = parseFloat(value);
          return numericValue >= -180 && numericValue <= 180;
        }
      ),
  });
};

export const changePasswordSchema = Yup.object({
  oldPassword: Yup.string().required("Please enter the old password.").trim(),

  newPassword: Yup.string()
    .required("Please enter the new password.")
    .trim()
    .min(8, "Password must be at least 8 characters long."),

  confirmPassword: Yup.string()
    .required("Please confirm your password.")
    .trim()
    .oneOf([Yup.ref("newPassword"), null], "Passwords must match."),
});

export const EditPofileSchema = () => {
  const { t } = useTranslation();

  return Yup.object({
    companyName: Yup.string()
      .trim()
      .required(t("EditRecruiterValidation.companyName.required"))
      .max(80, t("EditRecruiterValidation.companyName.max")),

    ownerName: Yup.string()
      .trim()
      .required(t("EditRecruiterValidation.ownerName.required"))
      .matches(fullNameRegex, t("EditRecruiterValidation.ownerName.matches"))
      .max(60, t("EditRecruiterValidation.ownerName.max")),

    ownerEmail: Yup.string()
      .required(t("EditRecruiterValidation.ownerEmail.required"))
      .email(t("EditRecruiterValidation.ownerEmail.email"))
      .matches(emailRegex, t("EditRecruiterValidation.ownerEmail.matches")),

    companyTagline: Yup.string()
      .optional(t("EditRecruiterValidation.companyTagline.optional"))
      .min(2, t("EditRecruiterValidation.companyTagline.min"))
      .max(100, t("EditRecruiterValidation.companyTagline.max")),

    established: Yup.string()
      .optional(t("EditRecruiterValidation.established.optional"))
      .min(4, t("EditRecruiterValidation.established.min")),

    logo: Yup.string().required(t("EditRecruiterValidation.logo.required")),

    email: Yup.string()
      .required(t("EditRecruiterValidation.email.required"))
      .email(t("EditRecruiterValidation.email.email"))
      .matches(emailRegex, t("EditRecruiterValidation.email.matches")),

    websiteLink: Yup.string()
      .optional(t("EditRecruiterValidation.websiteLink.optional"))
      .trim()
      .url(t("EditRecruiterValidation.websiteLink.url")),

    address: Yup.string()
      .optional(t("EditRecruiterValidation.address.optional"))
      .trim()
      .min(2, t("EditRecruiterValidation.address.min"))
      .max(260, t("EditRecruiterValidation.address.max")),

    phoneNumber: Yup.string()
      .optional(t("EditRecruiterValidation.phoneNumber.optional"))
      .matches(/^[0-9]{6,12}$/, t("EditRecruiterValidation.phoneNumber.matches"))
      .test(
        "not-all-same",
        t("EditRecruiterValidation.phoneNumber.test"),
        (value) => !/^(\d)\1{5,11}$/.test(value)
      ),
  });
};

export const useRegisterCompanyValidationSchema = () => {
  const { t } = useTranslation();
  return Yup.object({
    companyName: Yup.string()
      .trim()
      .required(t("validation.registerCompany.companyName.required"))
      .max(80, t("validation.registerCompany.companyName.max")),
    ownerName: Yup.string()
      .trim()
      .required(t("validation.registerCompany.ownerName.required"))
      .matches(
        /^[A-Za-z\s]{1,50}$/,
        t("validation.registerCompany.ownerName.matches")
      )
      .max(60, t("validation.registerCompany.ownerName.max")),
    ownerEmail: Yup.string()
      .required(t("validation.registerCompany.ownerEmail.required"))
      .email(t("validation.registerCompany.ownerEmail.email")),
    companyTagline: Yup.string()
      .trim()
      .min(2, t("validation.registerCompany.companyTagline.min"))
      .max(100, t("validation.registerCompany.companyTagline.max")),
    established: Yup.string()
      .optional()
      .min(4, t("validation.registerCompany.established.min")),
    industry: Yup.string().optional(
      t("validation.registerCompany.industry.optional")
    ),
    industrySector: Yup.string().optional(
      t("validation.registerCompany.industrySector.optional")
    ),
    jobCategory: Yup.string().optional(
      t("validation.registerCompany.jobCategory.optional")
    ),
    logo: Yup.string().required(t("validation.registerCompany.logo.required")),
    gstin: Yup.string().notRequired(),
    pan: Yup.string().notRequired(),
    iec: Yup.string().notRequired(),
    // email: Yup.string()
    //   .required(t("validation.registerCompany.email.required"))
    //   .email(t("validation.registerCompany.email.email")),
    websiteLink: Yup.string()
      .optional()
      .trim()
      .url(t("validation.registerCompany.websiteLink.url")),
    address: Yup.string()
      .optional()
      .trim()
      .min(2, t("validation.registerCompany.address.min"))
      .max(260, t("validation.registerCompany.address.max")),
    address2: Yup.string().optional().trim(),
    state: Yup.string()
      .optional(t("validation.registerCompany.state.optional"))
      .trim(),
    employee: Yup.string()
      .optional(t("validation.registerCompany.employee.optional"))
      .trim(),
    phoneNumber: Yup.string()
      .optional(t("validation.registerCompany.phoneNumber.optional"))
      .matches(
        /^[0-9]{6,12}$/,
        t("validation.registerCompany.phoneNumber.matches")
      )
      .test(
        "not-all-same",
        t("validation.registerCompany.phoneNumber.notAllSame"),
        (value) => !/^(\d)\1{5,11}$/.test(value)
      ),
    landline: Yup.string()
      .optional(t("validation.registerCompany.landline.optional"))
      .matches(
        /^[0-9]{6,12}$/,
        t("validation.registerCompany.landline.matches")
      )
      .test(
        "valid-format",
        t("validation.registerCompany.landline.validFormat"),
        (value) => !/^(\d)\1{5,11}$/.test(value)
      )
      .matches(
        /^[^0]+$/,
        t("validation.registerCompany.landline.startsWithZero")
      )
      .matches(
        /^[\d-()\s]+$/,
        t("validation.registerCompany.landline.charactersAllowed")
      ),
    zip: Yup.string()
      .optional(t("validation.registerCompany.zip.optional"))
      .matches(/^[0-9]{4,10}$/, t("validation.registerCompany.zip.matches"))
      .matches(
        /^[a-zA-Z0-9-\s]+$/,
        t("validation.registerCompany.zip.charactersAllowed")
      )
      .test(
        "not-all-same",
        t("validation.registerCompany.zip.notAllSame"),
        (value) => !/^(\d)\1+$/.test(value)
      ),
    city: Yup.string()
      .optional(t("validation.registerCompany.city.optional"))
      .trim(),
    country: Yup.string()
      .optional(t("validation.registerCompany.country.optional"))
      .trim(),
    workingHours: Yup.string()
      .optional(t("validation.registerCompany.workingHours.optional"))
      .trim(),
    facebook: Yup.string()
      .optional()
      .trim()
      .url(t("validation.registerCompany.facebook.url")),
    twitter: Yup.string()
      .optional()
      .trim()
      .url(t("validation.registerCompany.twitter.url")),
    linkedIn: Yup.string()
      .optional()
      .trim()
      .url(t("validation.registerCompany.linkedIn.url")),
    instagram: Yup.string()
      .optional()
      .trim()
      .url(t("validation.registerCompany.instagram.url")),
    description: Yup.string()
      .optional()
      .trim()
      .max(2400, t("validation.registerCompany.description.max")),
    password: Yup.string()
      .required(t("validation.registerCompany.password.required"))
      .trim()
      .min(8, t("validation.registerCompany.password.min")),
    // confirmPassword: Yup.string()
    //   .required(t("validation.registerCompany.confirmPassword.required"))
    //   .trim()
    //   .oneOf(
    //     [Yup.ref("password"), null],
    //     t("validation.registerCompany.confirmPassword.match")
    //   ),
  });
};
// export default { useRegisterCompanyValidationSchema, PostJobSchema };
// export default ;

export const validationSchema = Yup.object().shape({
  img: Yup.string().required("Please upload image."),
  firstName: Yup.string().required("Please enter first name."),
  lastName: Yup.string().required("Please enter last name."),
  email: Yup.string()
    .email("Invalid email address.")
    .required("Please enter email address."),
  phone: Yup.string().required("Please enter phone number."),
  country: Yup.string().required("Please select country."),
  state: Yup.string().required("Please select state."),
  city: Yup.string().required("Please select city."),
  completeAddress: Yup.string().required("Please enter complete address."),
  jobTitle: Yup.string().required("Please enter job title."),
  companyName: Yup.string().required("Please enter company name."),
  startDate: Yup.date().required("Please enter start date."),
  endDate: Yup.date().required("Please enter end date."),
  startEduDate: Yup.date().required("Please enter start date."),
  endEduDate: Yup.date().required("Please enter end date."),
  qualification: Yup.string().required("Please select qualification."),
  institute: Yup.string().required("Please select institute."),
  fieldOfStudy: Yup.string().required("Please select field of study."),
  certificateName: Yup.string().required("Please select certificate name."),
  issuingDate: Yup.date().required("Please enter issuing date."),
  language: Yup.string().required("Please select language."),
  proficiencyLevel: Yup.string().required("Please select proficiency level."),
});
