import {
  Box,
  Button,
  Divider,
  FormHelperText,
  InputAdornment,
  makeStyles,
  TextField,
  Typography,
} from "@material-ui/core";
import React, { useContext, useState } from "react";
import { Formik, Form } from "formik";
import * as yup from "yup";
import { AuthContext } from "../../../../context/Auth";
import OTPFlow from "./OTPFlow";
import Popup from "../../../../component/modals/LoginModal";
import { CircularProgress } from "@material-ui/core";
import ResetPassword from "./ResetPassword";
import ResetOTPFlow from "./ResetOTPFlow";
import { postApiHandler } from "../../../../config/service";
import toast from "react-hot-toast";
import { useTranslation } from "react-i18next";
const useStyles = makeStyles((theme) => ({
  authContainer: {
    display: "flex",
    flexDirection: "row",
    "& .MuiOutlinedInput-notchedOutline": {
      borderColor: "#AFB0B6",
    },
  },
  formContainer: {
    borderRadius: "20px 0px 0px 20px",
    backgroundColor: "white",
    padding: theme.spacing(4),
    "@media(max-width:1024px)": {
      padding: theme.spacing(4),
      borderRadius: "20px",
      width: "39vw",
    },
    "@media(max-width:768px)": {
      padding: theme.spacing(4),
      borderRadius: "20px",
      width: "40vw",
    },
    "@media(max-width:430px)": {
      borderRadius: "20px",
      padding: theme.spacing(2),
      width: "60vw",
    },
    position: "relative",
  },
  imageSection: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    "@media(max-width:1030px)": {
      display: "none",
      borderRadius: "20px",
    },
    "@media(max-width:430px)": {
      display: "none",
      borderRadius: "20px",
    },
  },
  image: {
    objectFit: "contain",
    borderRadius: "0px 20px 20px 0px",

    "@media(max-width:768px)": {
      width: "100%",
      objectFit: "contain",
      height: "100%",
      borderRadius: "0px 20px 20px 0px",
    },
  },
  staticContent: {
    top: "52px",
    width: "25%",
    marginRight: "48px",
    position: "absolute",
    color: "white",
    padding: theme.spacing(0),
    [theme.breakpoints.down("md")]: {
      top: "132px",
      width: "21%",
      marginLeft: "-60px",
    },
  },
  heading: {
    fontWeight: 700,
    lineHeight: "29px",
    fontSize: 24,
    fontFamily: "Inter",
    color: "#262626",
    "@media(max-width:1200px)": {
      fontSize: 22,
      lineHeight: "28px",
    },
    "@media(max-width:992px)": {
      fontSize: 18,
      lineHeight: "24px",
    },
    "@media(max-width:767px)": {
      fontSize: 16,
      lineHeight: "18px",
    },
  },
  heading1: {
    lineHeight: "20px",
  },
  remember: {
    marginTop: "10px",
    display: "flex",
    justifyContent: "space-between",
    "@media(max-width:1024px)": {
      gap: "52px",
    },
    "@media(max-width:768px)": {
      gap: "42px",
    },
    "@media(max-width:430px)": {
      gap: "32px",
    },
  },
  staticheading1: {
    fontWeight: 400,
    fontSize: 20,
    lineHeight: "24px",
    color: "#FFFFFF",
    fontFamily: "Inter",
    marginBottom: theme.spacing(1),
  },
  staticheading2: {
    fontWeight: 700,
    fontSize: 20,
    lineHeight: "24px",
    color: "#FFFFFF",
    fontFamily: "Inter",
  },
  closeButton: {
    position: "absolute",
    top: theme.spacing(-3),
    right: theme.spacing(-57),
    height: "31px",
    width: "31px",
    color: theme.palette.grey[900],
    backgroundColor: "white",
    "@media(max-width:1030px)": {
      top: "-19PX",
      right: "-28PX",
    },
    "&:hover": {
      color: theme.palette.grey[900],
      backgroundColor: "white",
    },
  },
  btnBox: {
    marginTop: "30px",
    marginBottom: "10px",
  },
  btn: {
    backgroundColor: "#5E58E4",
    color: "#fff",
  },
}));

const Forgot = ({
  open,
  onClose,
  handletestClick,
  handResetClick,
  handleResetOTPClick,
  setEmail,
}) => {
  const auth = useContext(AuthContext);
  const classes = useStyles();
  const [isLoading, setIsLoading] = useState(false);
  const [openOTP, setOpenOTP] = React.useState(false);
  const { t } = useTranslation();
  const handleOTPOpen = () => {
    onClose();
    console.log("handle otp clicked", handleResetOTPClick);

    handleResetOTPClick();
  };

  const handleOTPClose = () => setOpenOTP(false);

  const [openReset, setOpenReset] = React.useState(false);
  const handleOResetpen = () => {
    onClose();
    console.log("handle otp clicked", handResetClick);
    handResetClick();
  };

  const handleResetClose = () => setOpenReset(false);

  const formInitialSchema = {
    email: "",
  };

  const formValidationSchema = yup.object().shape({
    email: yup
      .string()
      .matches(
        /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
        t("forgotPasswordValidation.email.invalidEmail")
      )
      .max(256, t("forgotPasswordValidation.email.maxLength"))
      .required(t("forgotPasswordValidation.email.required")),
  });

  const handleFormSubmit = async (values) => {
    console.log("Email entered:", values.email);
    const data = {
      email: values.email,
    };

    try {
      setIsLoading(true);
      const response = await postApiHandler("resendOtpSeeker", data);

      if (response?.responseCode === 200) {
        toast.success(response?.responseMessage);
        setEmail(values.email);
        handleOTPOpen();
      } else {
        toast.error(response?.responseMessage || "Something went wrong.");
      }
    } catch (error) {
      console.log("Koca: error ", error);

      const errorMessage =
        error?.response?.data?.responseMessage ||
        "An error occurred. Please try again.";
      toast.error(errorMessage);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <>
      <Popup open={open} handleClose={onClose}>
        <Box className={classes.authContainer}>
          <Box className={classes.formContainer}>
            <Typography
              variant="body6"
              align="left"
              className={classes.heading}
            >
              {t("seekerforgotPassword.heading")}
            </Typography>
            <Box my={2}>
              <Typography variant="h6" className={classes.heading1}>
                {t("seekerforgotPassword.subHeading")}
              </Typography>
            </Box>

            <Formik
              initialValues={formInitialSchema}
              validationSchema={formValidationSchema}
              onSubmit={(values) => handleFormSubmit(values)}
            >
              {({
                errors,
                handleBlur,
                handleChange,
                handleSubmit,
                touched,
                values,
              }) => (
                <Form>
                  <Box mt={3}>
                    <TextField
                      fullWidth
                      variant="outlined"
                      placeholder={t("seekerforgotPassword.emailPlaceholder")}
                      name="email"
                      id="email"
                      inputProps={{
                        maxLength: 62,
                      }}
                      value={values.email}
                      error={Boolean(touched.email && errors.email)}
                      onBlur={handleBlur}
                      onChange={handleChange}
                      onInput={(e) => {
                        let value = e.target.value;
                        value = value.replace(/\s/g, "").toLowerCase();
                        e.target.value = value;
                        window.localStorage.setItem("email", e.target.value);
                      }}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <Box display="flex" alignItems="center">
                              <img
                                src="/images/lock1.svg"
                                style={{ color: "#00000080" }}
                              />
                              <Divider
                                orientation="vertical"
                                flexItem
                                style={{ height: "34px", marginLeft: "10px" }}
                              />
                            </Box>
                          </InputAdornment>
                        ),
                      }}
                    />
                    <FormHelperText error>
                      {touched.email && errors.email}
                    </FormHelperText>
                  </Box>

                  <Box className={classes.btnBox}>
                    <Button
                      className={classes.btn}
                      variant="contained"
                      color="secondary"
                      fullWidth
                      type="submit"
                      disabled={isLoading}
                    >
                      {isLoading ? (
                        <CircularProgress size={24} color="inherit" />
                      ) : (
                        t("seekerforgotPassword.sendOtp")
                      )}
                    </Button>
                  </Box>
                </Form>
              )}
            </Formik>
          </Box>
          <Box className={classes.imageSection}>
            <img
              src="images/otp.png"
              alt="Illustration"
              className={classes.image}
            />
            <Box className={classes.staticContent}>
              <p className={classes.staticheading1}>
                {t("seekerforgotPassword.staticHeading1")}
              </p>
              <h1 className={classes.staticheading2}>
                {t("seekerforgotPassword.staticHeading2")}
              </h1>
            </Box>
          </Box>
        </Box>
      </Popup>
      <ResetPassword open={openReset} onClose={handleResetClose} />
      <ResetOTPFlow open={openOTP} onClose={handleOTPClose} />
      {/* <OTPFlow open={openOTP} onClose={handleOTPClose} /> */}
    </>
  );
};

export default Forgot;
