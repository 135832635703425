import React, { useState, useRef, useEffect, useContext } from "react";
import {
  Toolbar,
  IconButton,
  Typography,
  Drawer,
  List,
  ListItem,
  ListItemText,
  Box,
  Button,
  makeStyles,
  Popover,
  Avatar,
  Divider,
  CircularProgress,
  ListItemIcon,
} from "@material-ui/core";
import MenuIcon from "@material-ui/icons/Menu";
import PopupState, { bindTrigger, bindPopover } from "material-ui-popup-state";
import { styled } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import ExpandLessIcon from "@material-ui/icons/ExpandLess";
import { useHistory, useLocation } from "react-router-dom";
import MegaMenu from "../MegaMenu";
import SignUp from "../../views/pages/jobSeeker/Auth/SignUp";
import Login from "../../views/pages/jobSeeker/Auth/Login";
import CompleteProfile from "../../views/pages/jobSeeker/Auth/CompleteProfile";
import Forgot from "../../views/pages/jobSeeker/Auth/Forgot";
import OTPFlow from "../../views/pages/jobSeeker/Auth/OTPFlow";
import Success from "../../views/pages/jobSeeker/Auth/CompleteProfile";
import Logo from "../../assets/logoipsum.svg";
import ResetPassword from "../../views/pages/jobSeeker/Auth/ResetPassword";
import ResetOTPFlow from "../../views/pages/jobSeeker/Auth/ResetOTPFlow";
import { AuthContext } from "../../context/Auth";
import SeekerLogout from "../modals/SeekerLogout";
import { ProfileContext } from "../../context/ProfileContext";
import HomeIcon from "@material-ui/icons/Home";
import WorkIcon from "@material-ui/icons/Work";
import LibraryBooksIcon from "@material-ui/icons/LibraryBooks";
import BusinessIcon from "@material-ui/icons/Business";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import PersonAddIcon from "@material-ui/icons/PersonAdd";
import LoginIcon from "@material-ui/icons/Person";
import { useTranslation } from "react-i18next";
import {
  MenuItem,
  Select,
  FormControl,
  InputLabel,
  useTheme,
} from "@mui/material";

const useStyles = makeStyles((theme) => ({
  menuItems: {
    cursor: "pointer",
    display: "flex",
    justifyContent: "flex-end",
    gap: "32px",
    alignItems: "center",
    position: "relative",
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
      alignItems: "flex-start",
      gap: "15px",
    },
    [theme.breakpoints.down("md")]: {
      gap: "5px",
    },
  },
  menuBtn: {
    display: "flex",
    justifyContent: "flex-end",
    gap: "20px",
    alignItems: "center",
    [theme.breakpoints.down("md")]: {
      gap: "10px",
    },
  },
  drawerList: {
    width: 250,
    height: "100%",
  },
  drawerListItem: {
    padding: "16px 24px",
    borderBottom: `1px solid ${theme.palette.divider}`,
    "&:hover": {
      backgroundColor: theme.palette.action.hover,
    },
  },
  drawerListItemText: {
    fontSize: "18px",
    fontWeight: "400",
    color: "#000000CC",
  },
  drawerHeader: {
    padding: "16px 24px",
    fontSize: "20px",
    fontWeight: "bold",
    borderBottom: `1px solid ${theme.palette.divider}`,
  },
  navbar: {
    padding: "23px",
    paddingLeft: "5.8%",
    paddingRight: "5.8%",
    "@media(max-width:1024px)": {
      paddingLeft: "2%",
      paddingRight: "2%",
    },

    [theme.breakpoints.down("sm")]: {
      paddingLeft: "20px",
      paddingRight: "20px",
    },
    boxShadow: "0px 4px 4px 0px #0000000D",
  },
  toolbar: {
    paddingLeft: "0px",
    paddingRight: "0px",
    width: '100%',
    display: 'flex',
    // justifyContent: 'space-between',
    alignItems: 'center',
  },
  iconColor: {
    color: "#000000CC",
    opacity: "0.9",
  },
  logo: {
    height: "60px",
    width: "60px",
    border: "1px solid #5E58E4",
    borderRadius: "50px",
    background:
      "radial-gradient(50% 50% at 50% 50%,  #5E58E4 50%, #5E58E4 100%)",
    color: "white",
    marginRight: "20px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    textAlign: "center",
  },
  btn: {
    width: "102px",
  },
  menuiconStyle: {
    fontSize: "38px",
  },
  popoverContent: {
    // width: "170px",
    height: "auto",
    boxSizing: "border-box",
  },
  iconAndName: {
    display: "flex",
    alignItems: "center",
    gap: "16px",
    padding: "5px 20px 5px 20px",

    cursor: "pointer",
    "& p": {
      lineHeight: "35px",
      whiteSpace: "nowrap",
    },
    "&:hover": {
      backgroundColor: "#f0f0f0",

      transition: "background-color 0.3s ease",
    },
  },
  activeTab: {
    color: "#5E58E4",
  },
  sideBarList: {
    // border: '1px solid red',
    padding: 0,
  },
  listItem: {
    display: "flex",
    gap: "20px",
    alignItems: "center",
    paddingLeft: "26px",
    paddingRight: "26px",
    paddingTop: "15px",
    paddingBottom: "15px",
    transition: "background-color 0.3s, color 0.3s",
    "&:hover": {
      backgroundColor: "#5E58E4",
      color: "white", // Change text color on hover
      "& $listItemIcon img": {
        filter: "brightness(0) invert(1)", // Change icon color to white on hover
      },
      "& $listItemText": {
        color: "white", // Ensure text color changes to white
      },
    },
  },
  listItemIcon: {
    height: "24px",
    width: "24px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    objectFit: 'contain',
  },
  listItemText: {
    fontSize: 16,
    fontWeight: "400",
    lineHeight: "21px",
    fontFamily: "Inter",
    color: "#1E252E",
  },
  selected: {
    backgroundColor: "#5E58E4",
    color: "#FFFFFF",
    fontSize: "16px",
    "& $listItemIcon img": {
      filter: "brightness(0) invert(1)", // Change icon color for selected item
    },
    "& $listItemText": {
      color: "#FFFFFF",
      fontWeight: "500",
      fontSize: "16px",
    },
  },
}));

const Header = () => {
  const history = useHistory();
  const classes = useStyles();
  const location = useLocation();
  const {
    profile,
    getProfile,
    avatarSrc,
    setAvatarSrc,
    notifications,
    setNotifications,
  } = useContext(ProfileContext);
  const theme = useTheme();

  const [drawerOpen, setDrawerOpen] = useState(false);
  const [isMegaMenuOpen, setIsMegaMenuOpen] = useState(false);
  const [open, setOpen] = React.useState(false);
  const [opensignup, setOpenSignup] = React.useState(false);
  const [openForgot, setOpenForgot] = React.useState(false);
  const [openOTP, setOpenOTP] = React.useState(false);
  const [openResetOTP, setOpenResetOTP] = React.useState(false);
  const [email, setEmail] = useState("");
  const [openSuccess, setOpenSuccess] = React.useState(false);
  const [openReset, setOpenReset] = React.useState(false);
  const megaMenuRef = useRef(null);
  const { userLogout } = useContext(AuthContext);
  const [isLoading, setIsLoading] = useState(false);
  const [logoutDialogOpen, setLogoutDialogOpen] = useState(false);
  const [loading2, setLoading] = useState(false);

  const [anchorEl, setAnchorEl] = useState(null);
  const handlePopoverOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    console.log("Koca: handlePopoverClose is calling... ");
    setAnchorEl(null);
  };
  const handleLogOut = async () => {
    setIsLoading(true);
    try {
      await userLogout();
      history.push("/");
      setAnchorEl(null);
    } catch (error) {
      console.error("Error during logout:", error);
    } finally {
      setTimeout(() => {
        setIsLoading(false);
        history.push("/");
      }, 500);
    }
  };
  // function getCookie(name) {
  //   const value = `; ${document.cookie}`;
  //   const parts = value.split(`; ${name}=`);
  //   if (parts.length === 2) return parts.pop().split(";").shift();
  // }

  // Retrieve the token
  // const token = getCookie("token");
  const user = sessionStorage.getItem("user");
  let token = null;
  let userEmail = null;

  // Check if user exists in sessionStorage before parsing
  if (user) {
    try {
      const parsedUser = JSON.parse(user);
      token = parsedUser?.token || null; // Safely access the token if it exists
      userEmail = parsedUser?.email || null;
    } catch (error) {
      console.error("Error parsing user from sessionStorage:", error);
    }
  } else {
    console.warn("No user found in sessionStorage");
  }
  console.log("Koca: token ", token);

  const handleResetOpen = () => {
    setOpenReset(true);
    setOpenResetOTP(false);
  };

  const handleResetClose = () => setOpenReset(false);

  const handleOpen = () => {
    setOpenSignup(false);
    setOpen(true);
  };

  const handleClose = () => setOpen(false);
  const handleSignupOpen = () => {
    setOpenSignup(true);
    setOpen(false);
  };

  const handleSignupClose = () => setOpenSignup(false);

  const handleForgotOpen = () => {
    setOpenForgot(true);
    setOpen(false);
  };

  const handleForgotClose = () => setOpenForgot(false);

  const handleOTPOpen = () => {
    setOpenOTP(true);
    setOpenForgot(false);
  };

  const handleOTPClose = () => setOpenOTP(false);

  const handleResetOTPOpen = () => {
    setOpenResetOTP(true);
    setOpenForgot(false);
  };

  const handleResetOTPClose = () => setOpenResetOTP(false);

  const handScuccessClick = () => {
    setOpenOTP(false);
    setOpenSuccess(true);
  };

  const handleSuccessClose = () => setOpenSuccess(false);

  const handleDrawerToggle = () => {
    setDrawerOpen(!drawerOpen);
  };

  const toggleMegaMenu = () => {
    setIsMegaMenuOpen(!isMegaMenuOpen);
  };
  const handleCloseMenu = () => {
    setIsMegaMenuOpen(false);
  };
  const handleClick = () => {
    history.push("/");
  };

  const handleLogoClick = () => {
    history.push("/");
  };
  const handleNavigateToProfile = () => {
    history.push("/myprofile");
  };

  const isMobile = useMediaQuery((theme) => theme.breakpoints.down("sm"));

  const { t, i18n } = useTranslation(); // Access translations

  const navigationItems = [
    "Home",
    "BrowseJobs",
    "FindJobs",
    "Blogs",
    "Employer",
  ];

  const handleNavigation = (text) => {
    switch (text) {
      case "Home":
        history.push("/");
        break;
      case "BrowseJobs":
        toggleMegaMenu();
        break;
      case "FindJobs":
        history.push("/job-search");
        break;
      case "Blogs":
        history.push("/blog");
        break;
      case "Employer":
        history.push("/recruiterlandingpage");
        break;
      case "Logout":
        setLogoutDialogOpen(true);
        break;
      default:
        break;
    }
    handlePopoverClose(); // Close popover when navigating
  };

  const getActiveTabClass = (path) => {
    switch (path) {
      case "/":
        return "Home";
      case "/job-search":
        return "Find Jobs";
      case "/blog":
        return "Blogs";
      // case "/recruiterlandingpage":
      //   return "Employer";
      default:
        return "";
    }
  };
  const handleListItemClick = (path) => {
    history.push(path);
  };
  const menuItems = [
    {
      text: "Home",
      icon: "/images/DashboardIcon.svg",
      iconOnFocus: "/images/DashboardIcon.svg",
      path: "/recruiterDashboard",
    },
    {
      text: "Find Jobs",
      icon: "/images/icons8-find.svg",
      iconOnFocus: "/images/icons8-find.svg",
      path: "/CompanyProfile",
    },
    {
      text: "Blogs",
      icon: "/images/PostJobBlackIcon.svg",
      iconOnFocus: "/images/PostJobBlackIcon.svg",
      path: "/postNewJob",
    },
    {
      text: "Employer",
      icon: "/images/ProfileBlackIcon.svg",
      iconOnFocus: "/images/ProfileBlackIcon.svg",
      path: "/ManageJob",
    },
    ...(token
      ? [
        {
          text: "Logout",
          icon: "/images/SubscriptionBlackIcon.svg",
          iconOnFocus: "/images/SubscriptionBlackIcon.svg",
          onClick: handleLogOut,
        },
      ]
      : [
        {
          text: "Sign Up",
          icon: "/images/ApplicantBlackIcon.svg",
          iconOnFocus: "/images/ApplicantBlackIcon.svg",
          onClick: handleSignupOpen,
        },
        {
          text: "Log In",
          icon: "/images/LogoutBlackIcon.svg",
          iconOnFocus: "/images/LogoutBlackIcon.svg",
          onClick: handleOpen,
        },
      ]
    )
  ];

  const drawerList = (
    <Box className={classes.drawerList}>
      {/* Drawer Header with Logo */}
      <Box className={classes.drawerHeader}>
        <div className={classes.logoContainer} onClick={handleLogoClick}>
          <img src={Logo} alt="Logo" className={classes.logoImage} />
          {/* <Typography variant="h6" className={classes.logoText}>
            JobPortal
          </Typography> */}
        </div>
      </Box>

      {/* Drawer List */}
      <Box
        className={classes.drawerListContent}
        role="presentation"
        onClick={handleDrawerToggle}
        onKeyDown={handleDrawerToggle}
      >

        <List className={classes.sideBarList}>
          {menuItems.map((item, index) => (
            <ListItem
              button
              key={index}
              className={`${classes.listItem} ${item.path && location.pathname === item.path ? classes.selected : ""
                }`}
              onClick={() =>
                item.path ? handleListItemClick(item.path) : item.onClick?.()
              }
            >
              <ListItemIcon className={classes.listItemIcon}>
                <img
                  src={
                    location.pathname === item.path && item.iconOnFocus
                      ? item.iconOnFocus
                      : item.icon
                  }
                  alt="itemIcon"
                  style={{ objectFit: "contain" }}
                />
              </ListItemIcon>
              <ListItemText
                primary={item.text}
                classes={{ primary: classes.listItemText }}
              />
            </ListItem>

          ))}
        </List>
        {/* <List>
          {[
            { text: "Home", icon: <HomeIcon /> },
            { text: "Find Jobs", icon: <WorkIcon /> },
            { text: "Blogs", icon: <LibraryBooksIcon /> },
            { text: "Employer", icon: <BusinessIcon /> },
          ].map(({ text, icon }) => (
            <ListItem
              button
              key={text}
              className={`${classes.drawerListItem} ${
                getActiveTabClass(location.pathname) === text
                  ? classes.activeItem
                  : ""
              }`}
              onClick={() => handleNavigation(text)}
            >
              <Box display="flex" alignItems="center" gap={2}>
                <span className={classes.listItemIcon}>{icon}</span>{" "}
                <Typography variant="body1" className={classes.listItemText}>
                  {text}
                </Typography>
              </Box>
            </ListItem>
          ))}
        </List>  */}

        {/* Conditional Logout Button */}
        {token ? (
          <Button
            className={classes.logoutButton}
            onClick={handleLogOut}
            variant="contained"
            color="primary"
            fullWidth
          >
            Logout
          </Button>
        ) : (
          <>
            {/* Sign Up and Log In Buttons */}
            {/* <ListItem
              button
              className={classes.drawerListItem}
              onClick={handleSignupOpen}
            >
              <Box display="flex" alignItems="center" gap={2}>
                <PersonAddIcon />
                <Typography
                  variant="body1"
                  className={classes.drawerListItemText}
                >
                  Sign Up
                </Typography>
              </Box>
            </ListItem>
            <ListItem
              button
              className={classes.drawerListItem}
              onClick={handleOpen}
            >
              <Box display="flex" alignItems="center" gap={2}>
                <LoginIcon />
                <Typography
                  variant="body1"
                  className={classes.drawerListItemText}
                >
                  Log In
                </Typography>
              </Box>
            </ListItem> */}
          </>
        )}
      </Box>
    </Box>
  );

  const isPopoverOpen = Boolean(anchorEl);
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (anchorEl && !anchorEl.contains(event.target)) {
        handlePopoverClose();
      }
    };
    if (isPopoverOpen) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [anchorEl, isPopoverOpen]);

  useEffect(() => {
    getProfile();
  }, []);

  useEffect(() => {
    setAvatarSrc(profile?.profilePicture);
  }, [profile]);

  const handleChange = async (event) => {
    const language = event.target.value;
    sessionStorage.setItem('language', language);
    await i18n.changeLanguage(language);
  };

  return (
    <>
      <Box
        style={location.pathname === "/" ? { boxShadow: "none" } : {}}
        className={classes.navbar}
      >
        <Toolbar className={classes.toolbar}>
          {isMobile && (
            <IconButton
              edge="start"
              color="inherit"
              aria-label="menu"
              onClick={handleDrawerToggle}
            >
              <MenuIcon />
            </IconButton>
          )}
          <Box onClick={handleLogoClick} style={{ cursor: "pointer" }}>
            <img
              src={Logo}
              alt="logo"
              style={{ width: "235px", height: "60px", marginLeft: "10px" }}
            />
          </Box>
          {!isMobile && (
            <Box className={classes.menuItems} flexGrow={1}>
              {navigationItems.map((key) => (
                <Box
                  display="flex"
                  alignItems="center"
                  key={key}
                  onClick={() => handleNavigation(key)}
                  sx={{ cursor: "pointer" }}
                >
                  {key === "Employer" ? (
                    <Button
                      variant="contained"
                      color="secondary"
                      onClick={() => handleNavigation(key)}
                    >
                      {t(`navigations.${key}`)}{" "}
                      {/* Render the translated text */}
                    </Button>
                  ) : (
                    <Typography
                      variant="h4"
                      display="inline"
                      className={
                        getActiveTabClass(location.pathname) === key
                          ? classes.activeTab
                          : ""
                      }
                    >
                      {t(`navigations.${key}`)}{" "}
                      {/* Render the translated text */}
                    </Typography>
                  )}
                  {key === "BrowseJobs" &&
                    (isMegaMenuOpen ? (
                      <ExpandLessIcon className={classes.iconColor} />
                    ) : (
                      <ExpandMoreIcon className={classes.iconColor} />
                    ))}
                </Box>
              ))}
              <PopupState
                variant="popover"
                popupId="popup-popover0"
                className={classes.popover}
              >
                {(popupState) => (
                  <div
                    style={{ cursor: "pointer" }}
                    onClick={handlePopoverOpen}
                  >
                    {token && (
                      <>
                        <Avatar
                          className={classes.avatar}
                          src={avatarSrc}
                          alt="User Name"
                        />
                      </>
                    )}
                    <Popover
                      open={isPopoverOpen}
                      anchorEl={anchorEl}
                      onClose={handlePopoverClose}
                      anchorOrigin={{
                        vertical: "bottom",
                        horizontal: "center",
                      }}
                      transformOrigin={{
                        vertical: "top",
                        horizontal: "center",
                      }}
                    >
                      <div className={classes.popoverContent}>
                        <Box
                          onClick={handleNavigateToProfile}
                          sx={{
                            cursor: "pointer",
                            "&:hover": {
                              backgroundColor: "#f0f0f0", // Change this to your desired hover color
                              transition: "background-color 0.3s ease",
                            },
                          }}
                        >
                          <Typography
                            style={{
                              fontWeight: 500,
                              fontSize: "14px",
                              fontFamily: "Inter",
                              cursor: "pointer",
                              padding: "10px 20px",
                            }}
                          >
                            {userEmail && userEmail.length > 15
                              ? `${userEmail.slice(0, 15)}...`
                              : userEmail || ""}
                          </Typography>
                        </Box>
                        <Divider />
                        <Box
                          className={classes.iconAndName}
                          onClick={handleLogOut}
                        >
                          <img src="/images/logout.png" width={20} />
                          <Typography variant="body2">Logout</Typography>
                        </Box>
                      </div>
                    </Popover>
                  </div>
                )}
              </PopupState>

              {!token && (
                <Box className={classes.menuBtn}>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={handleSignupOpen}
                  >
                    {t("buttons.SignUp")}
                  </Button>
                  <Button
                    variant="contained"
                    color="secondary"
                    onClick={handleOpen}
                    className={classes.btn}
                  >
                    {t("buttons.Login")}
                  </Button>
                </Box>
              )}
              <FormControl sx={{ minWidth: 120 }}>
                {/* Select Component */}
                <Select
                  value={i18n.language}
                  onChange={handleChange}
                  sx={{
                    minWidth: 120,
                    borderRadius: "10px",
                    backgroundColor: "#fff",
                    color: theme.palette.text.primary,
                    "& .MuiSelect-icon": {
                      color: "#5E58E4",
                    },
                    "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                      borderColor: "#5E58E4",
                      "&:hover .MuiOutlinedInput-notchedOutline": {
                        borderColor: "#5E58E4",
                      },
                    },
                  }}
                >
                  <MenuItem value="en">English</MenuItem>
                  <MenuItem value="ar">عربي</MenuItem>
                </Select>
              </FormControl>
            </Box>
          )}
        </Toolbar>
        <Drawer
          anchor="left"
          open={isMobile ? drawerOpen : false}
          onClose={handleDrawerToggle}
        >
          {drawerList}
        </Drawer>
      </Box>
      <Box fullWidth>
        {" "}
        <MegaMenu onClose={handleCloseMenu} isOpen={isMegaMenuOpen} />
      </Box>
      <SignUp
        open={opensignup}
        onClose={handleSignupClose}
        handleLoginClick={handleOpen}
        handletestClick={handleOTPOpen}
        setEmail={setEmail}
      />

      <Login
        open={open}
        onClose={handleClose}
        handleSignUpClick={handleSignupOpen}
        handleForgotClick={handleForgotOpen}
        handletestClick={handleOTPOpen}
        setEmail={setEmail}
      />

      <Forgot
        open={openForgot}
        onClose={handleForgotClose}
        handleForgotClick={handleForgotOpen}
        handleResetOTPClick={handleResetOTPOpen}
        setEmail={setEmail}
      />

      <OTPFlow
        open={openOTP}
        onClose={handleOTPClose}
        handScuccessClick={handScuccessClick}
        email={email}
      />

      <ResetOTPFlow
        open={openResetOTP}
        onClose={handleResetOTPClose}
        handleResetClick={handleResetOpen}
        email={email}
      />

      <ResetPassword
        open={openReset}
        onClose={handleResetClose}
        handScuccessClick={handScuccessClick}
        email={email}
      />

      <Success open={openSuccess} onClose={handleSuccessClose} />
      <SeekerLogout
        open={logoutDialogOpen}
        onClose={() => setLogoutDialogOpen(false)}
      />
    </>
  );
};

export default Header;
